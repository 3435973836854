import classNames from 'classnames'

import styles from './CloseModule.module.scss'

type TProps = {
  className?: string
}

export const CloseIcon = ({ className }: TProps) => {
  return (
    <svg
      className={classNames(styles.container, className)}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.82425 9.99351L5.82813 5.55581L9.82425 1.15079C10.0245 0.893075 10.0137 0.506325 9.79936 0.263155C9.58505 0.0199842 9.23952 0.00237077 9.00633 0.22273L4.99853 4.59507L1.06084 0.190052C0.832968 -0.0633505 0.464949 -0.0633505 0.237078 0.190052C0.126477 0.312769 0.064265 0.479817 0.064265 0.654082C0.064265 0.828346 0.126477 0.995394 0.237078 1.11811L4.16893 5.51659L0.172813 9.91508C0.0622118 10.0378 0 10.2048 0 10.3791C0 10.5534 0.0622118 10.7204 0.172813 10.8431C0.283068 10.9655 0.432325 11.0337 0.587615 11.0327C0.740056 11.0337 0.886817 10.968 0.996574 10.8497L4.99853 6.43812L9.00633 10.9216C9.11658 11.0439 9.26584 11.1121 9.42113 11.1111C9.57439 11.1104 9.72127 11.0423 9.83009 10.9216C9.93992 10.798 10.0011 10.6304 9.99999 10.4562C9.99889 10.2819 9.93562 10.1153 9.82425 9.99351Z"
        fill="#9EA1AA"
      />
    </svg>
  )
}
