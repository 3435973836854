export const NotFoundPageLogo = () => {
  return (
    <svg width="350" height="331" viewBox="0 0 350 331" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="175.5" cy="165.5" r="165.5" fill="#F9FAFE" />
      <path
        d="M147.455 129.32H117.939V138.598H147.455C149.962 138.598 152 136.518 152 133.959C152 131.4 149.962 129.32 147.455 129.32Z"
        fill="url(#paint0_linear_20_76)"
      />
      <path
        d="M147.455 92.4375H117.939V101.715H147.455C149.962 101.715 152 99.6353 152 97.0763C152 94.5172 149.962 92.4375 147.455 92.4375Z"
        fill="url(#paint1_linear_20_76)"
      />
      <path
        d="M309.584 222.596C309.584 203.453 294.319 187.867 275.554 187.867H238.402V257.325H275.554C294.319 257.325 309.584 241.747 309.584 222.596ZM265.895 237.633C264.251 237.069 263.069 235.6 262.842 233.853L261.554 223.995L252.94 235.979C252.054 237.224 250.675 237.881 249.274 237.881C248.342 237.881 247.402 237.587 246.592 236.976C244.569 235.461 244.13 232.562 245.614 230.498L260.902 209.213C262.001 207.69 263.895 206.994 265.698 207.466C267.493 207.93 268.827 209.468 269.077 211.347L270.637 223.323L284.986 208.68C286.758 206.871 289.645 206.871 291.418 208.68C293.19 210.489 293.19 213.427 291.418 215.236L270.561 236.52C269.342 237.765 267.531 238.198 265.895 237.633Z"
        fill="url(#paint2_linear_20_76)"
      />
      <path
        d="M349.992 250.639C349.97 250.299 349.462 242.344 344.826 234.388C340.841 227.538 333.083 219.498 318.455 218.16V227.492C326.72 228.451 332.902 232.254 336.848 238.849C340.212 244.47 340.833 250.276 340.909 251.096V303.722H203.523V78.6338C203.523 48.8838 186.902 33.6532 172.955 26.1152C158.197 18.1365 143.598 17.0464 142.985 17.0077C142.886 17 142.78 17 142.682 17H4.54545C2.03788 17 0 19.072 0 21.6388V118.002C0 120.561 2.03788 122.64 4.54545 122.64H28.8106V113.363H9.09091V26.2775H142.508C143.909 26.3935 156.333 27.6228 168.705 34.3103C185.773 43.5415 194.432 58.4551 194.432 78.6338V308.361C194.432 310.928 196.47 313 198.977 313H345.455C347.962 313 350 310.928 350 308.361V250.91C350 250.817 350 250.724 349.992 250.639Z"
        fill="url(#paint3_linear_20_76)"
      />
      <path
        d="M37.6602 118.002C37.6602 137.145 52.9253 152.724 71.6905 152.724H108.85V83.2734H71.6905C52.9253 83.2734 37.6602 98.852 37.6602 118.002ZM73.592 116.951L87.9405 102.308C89.7132 100.491 92.592 100.491 94.3647 102.308C96.145 104.117 96.145 107.055 94.3647 108.864L73.5162 130.148C72.2889 131.393 70.4783 131.826 68.842 131.262C67.2056 130.689 66.0238 129.228 65.7965 127.481L64.5086 117.624L55.895 129.607C55.0011 130.852 53.6223 131.501 52.2283 131.501C51.2889 131.501 50.3495 131.215 49.5389 130.604C47.5162 129.089 47.0768 126.19 48.5617 124.126L63.8571 102.841C64.948 101.318 66.8495 100.622 68.645 101.094C70.4405 101.558 71.7814 103.096 72.0238 104.975L73.592 116.951Z"
        fill="url(#paint4_linear_20_76)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20_76"
          x1="117.892"
          y1="265.944"
          x2="236.999"
          y2="63.7965"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#094E91" />
          <stop offset="0.385" stop-color="#0176D3" />
          <stop offset="0.64" stop-color="#0176D3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20_76"
          x1="117.892"
          y1="265.947"
          x2="236.999"
          y2="63.7996"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#094E91" />
          <stop offset="0.385" stop-color="#0176D3" />
          <stop offset="0.64" stop-color="#0176D3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20_76"
          x1="117.893"
          y1="265.942"
          x2="237"
          y2="63.7943"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#094E91" />
          <stop offset="0.385" stop-color="#0176D3" />
          <stop offset="0.64" stop-color="#0176D3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20_76"
          x1="117.892"
          y1="265.944"
          x2="236.999"
          y2="63.7963"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#094E91" />
          <stop offset="0.385" stop-color="#0176D3" />
          <stop offset="0.64" stop-color="#0176D3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_20_76"
          x1="117.893"
          y1="265.944"
          x2="237"
          y2="63.7972"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#094E91" />
          <stop offset="0.385" stop-color="#0176D3" />
          <stop offset="0.64" stop-color="#0176D3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
