import { TDescription, TEvents, TTasks } from '../../@types/types/templatesDetails'

export const description: Array<TDescription> = [
  { id: 1, title: 'Бизнес-модель:', text: 'B2C' },
  { id: 2, title: 'Сфера бизнеса:', text: 'Онлайн-образование' },
  { id: 3, title: 'Ниша бизнеса:', text: 'Иностранные языки' },
  { id: 4, title: 'Цели:', text: 'Автоматизированный курс в чат-боте.' },
  { id: 5, title: 'Тип решения:', text: 'Комплексная воронка' },
  { id: 6, title: 'Мессенджеры:', text: 'Telegram' },
  { id: 7, title: 'Интеграция с CRM:', text: 'НЕТ' },
  { id: 8, title: 'Интеграция с платёжными системами:', text: 'Prodamus' }
]

export const tasks: Array<TTasks> = [
  { id: 1, images: '', text: 'Полноценное взаимодействие «ученик – преподаватель»' },
  { id: 2, images: '', text: 'Выдача уроков и прием домашних заданий' },
  { id: 3, images: '', text: 'Прием платежей от ученика' },
  { id: 4, images: '', text: 'Персональная страница ученика, журнал успеваемости в гугл-таблице' },
  { id: 5, images: '', text: 'Экзаменационный созвон в zoom' },
  { id: 6, images: '', text: 'Выдача индивидуального сертификата по итогу обучения' }
]

export const events: Array<TEvents> = [
  {
    id: 1,
    text: 'Создание минилендинга с опросом о направлении обучения и желаемой форме обучения'
  },
  {
    id: 2,
    text: 'Знакомство с пользователем и запрос имени'
  },
  {
    id: 3,
    text: 'Тестирование пользователя для определения уровня знаний с подсчетом баллов и определения типа обучения'
  },
  {
    id: 4,
    text: 'Запись на бесплатную консультацию на свободные дату/время. Напоминания об этом событии за сутки и за 1 час'
  },
  {
    id: 5,
    text: 'Уведомление в Telegram менеджеру с временем и датой записи на бесплатную консультацию'
  },
  {
    id: 6,
    text: 'Подключение платежных сервисов'
  },
  {
    id: 7,
    text: 'Создание уроков для онлайн-школы и персонализированную странички учащегося с использованием минилендингов'
  },
  {
    id: 8,
    text: 'Создание записи в журнал студентов, дозапись успеваемости'
  },
  {
    id: 9,
    text: 'Отправка домашних заданий преподавателю, общение внутри бота ученик - учитель, обновление данных ученика'
  },
  {
    id: 10,
    text: 'Создание экзаменационного звонка в Zoom'
  },
  {
    id: 11,
    text: 'Выдача индивидуального сертификата после успешной сдачи экзамена'
  },
  {
    id: 12,
    text: 'Выдача рекомендаций по индивидуальному процессу обучения'
  }
]
