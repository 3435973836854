export const Load = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.71 5.70892L9 3.40892V12.9989C9 13.2641 9.10536 13.5185 9.29289 13.706C9.48043 13.8936 9.73478 13.9989 10 13.9989C10.2652 13.9989 10.5196 13.8936 10.7071 13.706C10.8946 13.5185 11 13.2641 11 12.9989V3.40892L13.29 5.70892C13.383 5.80265 13.4936 5.87704 13.6154 5.92781C13.7373 5.97858 13.868 6.00472 14 6.00472C14.132 6.00472 14.2627 5.97858 14.3846 5.92781C14.5064 5.87704 14.617 5.80265 14.71 5.70892C14.8037 5.61596 14.8781 5.50536 14.9289 5.3835C14.9797 5.26164 15.0058 5.13093 15.0058 4.99892C15.0058 4.86691 14.9797 4.7362 14.9289 4.61434C14.8781 4.49248 14.8037 4.38188 14.71 4.28892L10.71 0.28892C10.6149 0.197879 10.5028 0.126514 10.38 0.0789198C10.1365 -0.0210983 9.86346 -0.0210983 9.62 0.0789198C9.49725 0.126514 9.3851 0.197879 9.29 0.28892L5.29 4.28892C5.19676 4.38216 5.1228 4.49285 5.07234 4.61467C5.02188 4.73649 4.99591 4.86706 4.99591 4.99892C4.99591 5.13078 5.02188 5.26135 5.07234 5.38317C5.1228 5.50499 5.19676 5.61568 5.29 5.70892C5.38324 5.80216 5.49393 5.87612 5.61575 5.92658C5.73757 5.97704 5.86814 6.00301 6 6.00301C6.13186 6.00301 6.26243 5.97704 6.38425 5.92658C6.50607 5.87612 6.61676 5.80216 6.71 5.70892ZM19 9.99892C18.7348 9.99892 18.4804 10.1043 18.2929 10.2918C18.1054 10.4793 18 10.7337 18 10.9989V16.9989C18 17.2641 17.8946 17.5185 17.7071 17.706C17.5196 17.8936 17.2652 17.9989 17 17.9989H3C2.73478 17.9989 2.48043 17.8936 2.29289 17.706C2.10536 17.5185 2 17.2641 2 16.9989V10.9989C2 10.7337 1.89464 10.4793 1.70711 10.2918C1.51957 10.1043 1.26522 9.99892 1 9.99892C0.734784 9.99892 0.48043 10.1043 0.292893 10.2918C0.105357 10.4793 0 10.7337 0 10.9989V16.9989C0 17.7946 0.316071 18.5576 0.87868 19.1202C1.44129 19.6828 2.20435 19.9989 3 19.9989H17C17.7956 19.9989 18.5587 19.6828 19.1213 19.1202C19.6839 18.5576 20 17.7946 20 16.9989V10.9989C20 10.7337 19.8946 10.4793 19.7071 10.2918C19.5196 10.1043 19.2652 9.99892 19 9.99892Z"
        fill="#032D60"
      />
    </svg>
  )
}
