export const LogoIcon = () => {
  return (
    <svg width="177" height="60" viewBox="0 0 177 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.2363 56.112V45.8172H22.4814V56.112H30.2363Z" fill="#094E91" />
      <path
        d="M30.2809 56.2176H22.374V45.7716L25.0221 43.1352H32.9289V53.5812L30.2809 56.2176ZM22.5886 56.0028H30.1917L32.7132 53.4924V43.35H25.1101L22.5886 45.8604V56.0028Z"
        fill="#094E91"
      />
      <path d="M30.2364 43.2432H22.4814L30.2364 35.5224V43.2432Z" fill="#094E91" />
      <path
        d="M30.2811 43.35H22.2212L32.9292 32.6892V40.7136L30.2811 43.35ZM22.7419 43.1352H30.1919L32.7134 40.6248V33.2076L22.7407 43.1364L22.7419 43.1352Z"
        fill="#094E91"
      />
      <path d="M22.4814 32.9484H30.2364L22.4814 40.6692V32.9484Z" fill="#094E91" />
      <path
        d="M22.374 40.9284V32.904L25.0221 30.2676H33.082L22.374 40.9284ZM22.5898 32.9928V40.41L32.5625 30.4812H25.1125L22.591 32.9916L22.5898 32.9928Z"
        fill="#094E91"
      />
      <path
        d="M30.2809 30.4824H22.374V20.0364L25.0221 17.4H32.9289V27.846L30.2809 30.4824ZM22.5886 30.2676H30.1917L32.7132 27.7572V17.6148H25.1101L22.5886 20.1252V30.2676Z"
        fill="#094E91"
      />
      <path d="M30.2363 30.3756V20.0808H22.4814V30.3756H30.2363Z" fill="#094E91" />
      <path d="M50.8403 4.56363L47.8965 7.49445L48.049 7.64633L50.9928 4.71552L50.8403 4.56363Z" fill="#094E91" />
      <path d="M30.2363 14.9328L37.9913 7.21204H48.3317V14.9328H30.2363Z" fill="#094E91" />
      <path
        d="M48.3761 15.0408H29.9771L40.532 4.53241H51.0242V12.4044L48.3761 15.0408ZM30.4965 14.826H48.2869L50.8084 12.3156V4.74601H40.6212L30.4965 14.826Z"
        fill="#094E91"
      />
      <path d="M27.6514 7.21198H35.4063L27.6514 14.9328V7.21198Z" fill="#094E91" />
      <path
        d="M27.5439 15.192V7.16759L30.192 4.53119H38.2519L27.5439 15.192ZM27.7597 7.25639V14.6736L37.7324 4.74479H30.2824L27.7609 7.25519L27.7597 7.25639Z"
        fill="#094E91"
      />
      <path d="M25.0665 14.9328H17.3115L25.0665 7.21198V14.9328Z" fill="#094E91" />
      <path
        d="M25.1112 15.0408H17.0513L27.7593 4.38V12.4044L25.1112 15.0408ZM17.572 14.826H25.022L27.5435 12.3156V4.8984L17.5708 14.8272L17.572 14.826Z"
        fill="#094E91"
      />
      <path d="M22.4816 7.21198L14.7266 14.9328H4.38623V7.21198H22.4816Z" fill="#094E91" />
      <path
        d="M14.771 15.0408H4.27881V7.16759L6.92688 4.53119H25.326L14.771 15.0396V15.0408ZM4.49456 14.826H14.6819L24.8065 4.74599H7.01607L4.49456 7.25639V14.826Z"
        fill="#094E91"
      />
      <path d="M32.7449 43.1677L29.8369 46.0629L29.9895 46.2148L32.8975 43.3196L32.7449 43.1677Z" fill="#094E91" />
      <path d="M32.7444 17.4309L29.8364 20.3261L29.989 20.478L32.897 17.5828L32.7444 17.4309Z" fill="#094E91" />
      <path
        d="M43.4333 56.2332C41.7459 56.2332 40.2465 55.8828 38.9423 55.1784C37.6358 54.474 36.6052 53.5128 35.8471 52.29C35.0914 51.0684 34.7129 49.674 34.7129 48.1104C34.7129 46.5468 35.0914 45.1224 35.8471 43.8996C36.6052 42.678 37.6358 41.7204 38.9423 41.0256C40.2465 40.3308 41.7447 39.9852 43.4333 39.9852C45.122 39.9852 46.5961 40.3308 47.9123 41.0256C49.2285 41.7204 50.259 42.6732 51.0051 43.8852C51.7536 45.0972 52.1248 46.506 52.1248 48.1104C52.1248 49.7148 51.7536 51.0684 51.0051 52.29C50.259 53.5116 49.2285 54.474 47.9123 55.1784C46.5961 55.8828 45.1051 56.2332 43.4333 56.2332ZM43.4333 52.4784C44.2011 52.4784 44.8881 52.3044 45.498 51.9504C46.1043 51.6 46.5864 51.096 46.9408 50.4396C47.2927 49.7856 47.4711 49.008 47.4711 48.1104C47.4711 47.2128 47.2939 46.4088 46.9408 45.762C46.5864 45.1176 46.1043 44.6184 45.498 44.268C44.8893 43.9152 44.2011 43.74 43.4333 43.74C42.6655 43.74 41.9809 43.914 41.371 44.268C40.7623 44.6184 40.2778 45.1176 39.9138 45.762C39.5498 46.4088 39.369 47.1924 39.369 48.1104C39.369 49.0284 39.5486 49.7856 39.9138 50.4396C40.2778 51.096 40.7623 51.6 41.371 51.9504C41.9797 52.3032 42.6679 52.4784 43.4333 52.4784Z"
        fill="#094E91"
      />
      <path
        d="M58.3694 55.9992L52.6538 40.2192H56.9857L61.7286 53.7984H59.6663L64.6153 40.2192H68.5037L73.3056 53.7984H71.2433L76.1345 40.2192H80.2012L74.4543 55.9992H70.0067L65.7941 44.3556H67.1489L62.7881 55.9992H58.3694Z"
        fill="#094E91"
      />
      <path
        d="M91.9859 39.984C93.2418 39.984 94.3664 40.2324 95.3595 40.7316C96.3503 41.2308 97.1314 41.9916 97.7015 43.0212C98.2692 44.0472 98.5548 45.3624 98.5548 46.9644V55.9992H93.959V47.6688C93.959 46.3992 93.6781 45.4608 93.1201 44.8548C92.562 44.2488 91.7701 43.9452 90.7492 43.9452C90.0224 43.9452 89.3679 44.0952 88.7906 44.3988C88.2108 44.7024 87.7601 45.168 87.4334 45.7932C87.1092 46.4184 86.9489 47.2212 86.9489 48.1968V56.0004H82.353V40.2204H86.7428V44.5908L85.9183 43.2708C86.486 42.216 87.302 41.4036 88.3615 40.836C89.4234 40.2708 90.6323 39.9864 91.9871 39.9864L91.9859 39.984Z"
        fill="#094E91"
      />
      <path
        d="M108.041 56.2332C106.687 56.2332 105.385 56.0736 104.139 55.7508C102.89 55.4256 101.904 55.02 101.177 54.534L102.709 51.2472C103.436 51.6984 104.294 52.0632 105.286 52.3476C106.279 52.632 107.254 52.7724 108.219 52.7724C109.278 52.7724 110.029 52.6464 110.473 52.3932C110.914 52.1376 111.134 51.7872 111.134 51.336C111.134 50.9664 110.962 50.6868 110.618 50.5008C110.276 50.3148 109.818 50.1732 109.249 50.076C108.679 49.9776 108.055 49.8804 107.376 49.782C106.7 49.6836 106.017 49.5528 105.328 49.386C104.643 49.2216 104.012 48.9708 103.444 48.6384C102.874 48.3072 102.419 47.856 102.075 47.2872C101.73 46.722 101.559 45.9888 101.559 45.0888C101.559 44.0916 101.847 43.2108 102.427 42.4488C103.007 41.6844 103.847 41.0856 104.947 40.644C106.046 40.2048 107.362 39.9852 108.894 39.9852C109.975 39.9852 111.075 40.1016 112.193 40.3356C113.312 40.572 114.245 40.9128 114.993 41.3616L113.461 44.6172C112.693 44.1684 111.924 43.86 111.146 43.6932C110.373 43.5288 109.619 43.4448 108.892 43.4448C107.872 43.4448 107.125 43.5804 106.653 43.8552C106.183 44.13 105.945 44.4804 105.945 44.91C105.945 45.3012 106.118 45.5952 106.461 45.7908C106.806 45.9864 107.262 46.1412 107.833 46.2588C108.401 46.3776 109.027 46.4808 109.704 46.5672C110.382 46.656 111.063 46.7892 111.751 46.9632C112.44 47.1396 113.063 47.388 113.622 47.7132C114.183 48.036 114.638 48.4752 114.994 49.0332C115.346 49.5888 115.524 50.3172 115.524 51.2172C115.524 52.1964 115.229 53.0604 114.639 53.814C114.052 54.5664 113.201 55.158 112.091 55.5876C110.984 56.0172 109.631 56.232 108.041 56.232V56.2332Z"
        fill="#094E91"
      />
      <path
        d="M126.22 56.2332C124.412 56.2332 122.825 55.8828 121.462 55.1784C120.097 54.474 119.04 53.5128 118.294 52.29C117.548 51.0684 117.174 49.674 117.174 48.1104C117.174 46.5468 117.543 45.1224 118.28 43.8996C119.016 42.678 120.022 41.7204 121.3 41.0256C122.575 40.3308 124.019 39.9852 125.63 39.9852C127.24 39.9852 128.581 40.3128 129.828 40.9668C131.074 41.6232 132.062 42.5616 132.789 43.7832C133.516 45.0048 133.88 46.4664 133.88 48.168C133.88 48.3444 133.868 48.5448 133.849 48.7692C133.829 48.9936 133.81 49.2036 133.791 49.3992H120.916V46.7304H131.374L129.607 47.5224C129.607 46.7016 129.44 45.9876 129.106 45.3816C128.771 44.7756 128.31 44.3004 127.72 43.9584C127.133 43.6176 126.444 43.4448 125.657 43.4448C124.87 43.4448 124.181 43.6164 123.581 43.9584C122.982 44.2992 122.514 44.7792 122.18 45.3948C121.847 46.0104 121.679 46.7388 121.679 47.5788V48.2832C121.679 49.1448 121.87 49.902 122.253 50.5584C122.637 51.2124 123.177 51.7116 123.875 52.0524C124.57 52.3956 125.392 52.566 126.335 52.566C127.178 52.566 127.92 52.44 128.56 52.1868C129.197 51.9312 129.779 51.5496 130.312 51.0408L132.758 53.6808C132.029 54.5016 131.115 55.134 130.017 55.5732C128.916 56.0124 127.651 56.232 126.217 56.232L126.22 56.2332Z"
        fill="#094E91"
      />
      <path
        d="M146.578 39.984C147.834 39.984 148.958 40.2324 149.951 40.7316C150.942 41.2308 151.723 41.9916 152.293 43.0212C152.861 44.0472 153.147 45.3624 153.147 46.9644V55.9992H148.551V47.6688C148.551 46.3992 148.27 45.4608 147.712 44.8548C147.154 44.2488 146.362 43.9452 145.341 43.9452C144.614 43.9452 143.96 44.0952 143.382 44.3988C142.803 44.7024 142.352 45.168 142.025 45.7932C141.701 46.4184 141.541 47.2212 141.541 48.1968V56.0004H136.945V40.2204H141.335V44.5908L140.51 43.2708C141.078 42.216 141.894 41.4036 142.953 40.836C144.015 40.2708 145.224 39.9864 146.579 39.9864L146.578 39.984Z"
        fill="#094E91"
      />
      <path
        d="M164.224 56.2332C162.733 56.2332 161.385 55.8972 160.188 55.2216C158.989 54.5484 158.043 53.6052 157.345 52.3932C156.647 51.1812 156.3 49.7532 156.3 48.1104C156.3 46.4676 156.647 45.0096 157.345 43.7976C158.043 42.5856 158.99 41.6472 160.188 40.9836C161.385 40.3176 162.731 39.9864 164.224 39.9864C165.559 39.9864 166.729 40.2804 167.731 40.8648C168.73 41.4516 169.508 42.342 170.056 43.536C170.608 44.7276 170.88 46.2528 170.88 48.1116C170.88 49.9704 170.617 51.4704 170.087 52.6704C169.555 53.874 168.795 54.768 167.802 55.356C166.809 55.9404 165.618 56.2344 164.222 56.2344L164.224 56.2332ZM165.019 52.4784C165.765 52.4784 166.444 52.3044 167.052 51.9504C167.661 51.6 168.146 51.096 168.51 50.4396C168.874 49.7856 169.054 49.008 169.054 48.1104C169.054 47.2128 168.875 46.4088 168.51 45.762C168.146 45.1176 167.661 44.6184 167.052 44.268C166.444 43.9152 165.765 43.74 165.019 43.74C164.273 43.74 163.567 43.914 162.957 44.268C162.348 44.6184 161.861 45.1176 161.5 45.762C161.136 46.4088 160.952 47.1924 160.952 48.1104C160.952 49.0284 161.134 49.7856 161.5 50.4396C161.861 51.096 162.348 51.6 162.957 51.9504C163.565 52.3032 164.254 52.4784 165.019 52.4784ZM169.174 55.9992V52.7724L169.263 48.0792L168.968 43.4148V34.2348H173.563V55.9992H169.174Z"
        fill="#094E91"
      />
    </svg>
  )
}
