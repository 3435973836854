export const UpDownArrows = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3785 9.22414C11.4643 9.31888 11.5676 9.39487 11.6825 9.44758C11.7974 9.50028 11.9214 9.52862 12.0471 9.5309C12.1728 9.53319 12.2977 9.50937 12.4143 9.46087C12.5309 9.41237 12.6368 9.34019 12.7257 9.24862C12.8147 9.15705 12.8848 9.04798 12.9318 8.92791C12.9789 8.80784 13.0021 8.67923 12.9999 8.54976C12.9976 8.42028 12.9701 8.29259 12.9189 8.17431C12.8678 8.05602 12.794 7.94957 12.702 7.86129L8.64428 3.68143L7.98257 3L7.32085 3.68143L3.26317 7.86C3.09251 8.04178 2.99801 8.28532 3.00003 8.53815C3.00205 8.79099 3.10042 9.03289 3.27395 9.21176C3.44748 9.39064 3.6823 9.49216 3.92781 9.49448C4.17333 9.49679 4.40991 9.39971 4.5866 9.22414L7.04618 6.69129V20.0357C7.04618 20.2915 7.14483 20.5367 7.32044 20.7176C7.49605 20.8984 7.73422 21 7.98257 21C8.23091 21 8.46909 20.8984 8.64469 20.7176C8.8203 20.5367 8.91896 20.2915 8.91896 20.0357V6.69129L11.3785 9.22414Z"
        fill="#094E91"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6215 14.7759C12.5357 14.6811 12.4324 14.6051 12.3175 14.5524C12.2026 14.4997 12.0786 14.4714 11.9529 14.4691C11.8272 14.4668 11.7023 14.4906 11.5857 14.5391C11.4691 14.5876 11.3632 14.6598 11.2743 14.7514C11.1853 14.8429 11.1152 14.952 11.0682 15.0721C11.0211 15.1922 10.9979 15.3208 11.0001 15.4502C11.0024 15.5797 11.0299 15.7074 11.0811 15.8257C11.1322 15.944 11.206 16.0504 11.298 16.1387L15.3557 20.3186L16.0174 21L16.6791 20.3186L20.7368 16.14C20.9075 15.9582 21.002 15.7147 21 15.4618C20.998 15.209 20.8996 14.9671 20.726 14.7882C20.5525 14.6094 20.3177 14.5078 20.0722 14.5055C19.8267 14.5032 19.5901 14.6003 19.4134 14.7759L16.9538 17.3087V3.96429C16.9538 3.70854 16.8552 3.46327 16.6796 3.28243C16.504 3.10159 16.2658 3 16.0174 3C15.7691 3 15.5309 3.10159 15.3553 3.28243C15.1797 3.46327 15.081 3.70854 15.081 3.96429L15.081 17.3087L12.6215 14.7759Z"
        fill="#BFC1C8"
      />
    </svg>
  )
}
