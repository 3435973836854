export const YouTubeFooter = () => {
  return (
    <svg width="75%" viewBox="0 0 64 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37505 1.20525C4.91888 1.42602 1.33933 4.91524 1.05014 9.36749C0.773069 13.6331 0.5 18.8817 0.5 23C0.5 27.1183 0.773069 32.3669 1.05014 36.6325C1.33933 41.0848 4.91888 44.574 9.37505 44.7947C15.8279 45.1144 25.0508 45.5 32 45.5C38.9492 45.5 48.1721 45.1144 54.625 44.7947C59.0811 44.574 62.6607 41.0848 62.9499 36.6325C63.2269 32.3669 63.5 27.1183 63.5 23C63.5 18.8817 63.2269 13.6331 62.9499 9.36749C62.6607 4.91525 59.0811 1.42602 54.625 1.20525C48.1721 0.885564 38.9492 0.5 32 0.5C25.0508 0.5 15.8279 0.885564 9.37505 1.20525ZM43.25 23L25.25 32V14L43.25 23Z"
        fill="white"
      />
    </svg>
  )
}
