import { useWindowSize } from '../../hooks/useWindowsSize'

const Google = () => {
  const { width: widthWindow = 0 } = useWindowSize()

  return (
    <svg
      width={widthWindow > 1024 ? '20' : widthWindow > 480 ? '18.5' : '15'}
      height={widthWindow > 1024 ? '20.5' : widthWindow > 480 ? '19' : '15.5'}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2117 11.2372C21.2117 10.4973 21.1453 9.78579 21.022 9.10278H11.1943V13.1392H16.8102C16.5683 14.4435 15.8331 15.5486 14.7279 16.2886V18.9068H18.1003C20.0734 17.0902 21.2117 14.415 21.2117 11.2372Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1941 21.4347C14.0115 21.4347 16.3735 20.5003 18.1 18.9067L14.7277 16.2885C13.7933 16.9146 12.598 17.2845 11.1941 17.2845C8.47627 17.2845 6.17587 15.449 5.35531 12.9825H1.86914V15.6861C3.58614 19.0964 7.115 21.4347 11.1941 21.4347Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35582 12.9825C5.14713 12.3564 5.02855 11.6876 5.02855 10.9999C5.02855 10.3121 5.14713 9.64337 5.35582 9.01728V6.31372H1.86965C1.16293 7.72242 0.759766 9.3161 0.759766 10.9999C0.759766 12.6837 1.16293 14.2774 1.86965 15.6861L5.35582 12.9825Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1941 4.71539C12.7261 4.71539 14.1016 5.24188 15.183 6.27587L18.1759 3.28298C16.3688 1.59918 14.0067 0.565186 11.1941 0.565186C7.115 0.565186 3.58614 2.90353 1.86914 6.31382L5.35531 9.01738C6.17587 6.55097 8.47627 4.71539 11.1941 4.71539Z"
        fill="#032D60"
      />
    </svg>
  )
}

export default Google
