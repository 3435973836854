import styles from './IconCheck.module.scss'

export const IconCheck = () => {
  return (
    <div className={styles.iconCheck}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.977974"
          d="M6.99073 2.99854C4.78172 2.99854 2.99072 4.78953 2.99072 6.99853V16.9985C2.99072 19.2075 4.78172 20.9985 6.99073 20.9985H16.9907C19.1997 20.9985 20.9907 19.2075 20.9907 16.9985V12.9985C20.9907 12.4465 20.5427 11.9985 19.9907 11.9985C19.4387 11.9985 18.9907 12.4465 18.9907 12.9985V16.9985C18.9907 18.1035 18.0957 18.9985 16.9907 18.9985H6.99073C5.88572 18.9985 4.99072 18.1035 4.99072 16.9985V6.99853C4.99072 5.89353 5.88572 4.99853 6.99073 4.99853H14.9907C15.5427 4.99853 15.9907 4.55053 15.9907 3.99853C15.9907 3.44653 15.5427 2.99854 14.9907 2.99854H6.99073ZM20.9907 3.99853C20.7347 3.99853 20.4667 4.08854 20.2717 4.27954L11.5527 12.8425C11.2957 13.0955 11.0367 13.0775 10.8347 12.7795L9.83472 11.3105C9.52872 10.8595 8.88673 10.7285 8.42873 11.0295C7.96973 11.3305 7.84073 11.9535 8.14673 12.4045L9.14673 13.8735C10.0477 15.2025 11.8107 15.3785 12.9597 14.2485L21.7097 5.65454C22.0997 5.27154 22.0997 4.66354 21.7097 4.27954C21.5147 4.08754 21.2467 3.99853 20.9907 3.99853Z"
          fill="#2A343D"
        />
      </svg>
    </div>
  )
}
