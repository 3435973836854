const Setings = () => {
  return (
    <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7969 17.2969C11.7969 16.5547 12.3985 15.9531 13.1406 15.9531L31.2812 15.9531C32.0234 15.9531 32.625 16.5547 32.625 17.2969C32.625 18.039 32.0234 18.6406 31.2812 18.6406H13.1406C12.3985 18.6406 11.7969 18.039 11.7969 17.2969Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 17.2969C0.375 16.5547 0.976617 15.9531 1.71875 15.9531H6.42188C7.16401 15.9531 7.76562 16.5547 7.76562 17.2969C7.76562 18.039 7.16401 18.6406 6.42188 18.6406H1.71875C0.976617 18.6406 0.375 18.039 0.375 17.2969Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76562 17.2969C7.76562 18.4101 8.66805 19.3125 9.78125 19.3125C10.8944 19.3125 11.7969 18.4101 11.7969 17.2969C11.7969 16.1837 10.8944 15.2812 9.78125 15.2812C8.66805 15.2812 7.76562 16.1837 7.76562 17.2969ZM9.78125 22C7.18379 22 5.07812 19.8943 5.07812 17.2969C5.07813 14.6994 7.18379 12.5938 9.78125 12.5938C12.3787 12.5938 14.4844 14.6994 14.4844 17.2969C14.4844 19.8943 12.3787 22 9.78125 22Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2344 5.20312C25.2344 4.46099 25.836 3.85938 26.5781 3.85938L31.2812 3.85938C32.0234 3.85938 32.625 4.46099 32.625 5.20312C32.625 5.94526 32.0234 6.54688 31.2812 6.54688H26.5781C25.836 6.54688 25.2344 5.94526 25.2344 5.20312Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 5.20312C0.375 4.46099 0.976617 3.85938 1.71875 3.85938L19.8594 3.85938C20.6015 3.85938 21.2031 4.46099 21.2031 5.20313C21.2031 5.94526 20.6015 6.54688 19.8594 6.54688L1.71875 6.54688C0.976617 6.54688 0.375 5.94526 0.375 5.20312Z"
        fill="#032D60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2031 5.20312C21.2031 6.31632 22.1056 7.21875 23.2188 7.21875C24.3319 7.21875 25.2344 6.31632 25.2344 5.20312C25.2344 4.08993 24.3319 3.1875 23.2188 3.1875C22.1056 3.1875 21.2031 4.08993 21.2031 5.20312ZM23.2188 9.90625C20.6213 9.90625 18.5156 7.80059 18.5156 5.20312C18.5156 2.60566 20.6213 0.5 23.2188 0.5C25.8162 0.5 27.9219 2.60566 27.9219 5.20313C27.9219 7.80059 25.8162 9.90625 23.2188 9.90625Z"
        fill="#032D60"
      />
    </svg>
  )
}

export default Setings
