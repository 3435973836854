export const DollarIcon = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#208;&#156;&#208;&#190;&#208;&#189;&#209;&#130;&#208;&#176;&#208;&#182;&#208;&#189;&#208;&#176;&#209;&#143; &#208;&#190;&#208;&#177;&#208;&#187;&#208;&#176;&#209;&#129;&#209;&#130;&#209;&#140; 5 &#208;&#186;&#208;&#190;&#208;&#191;&#208;&#184;&#209;&#143; 2 1">
        <g id="Group">
          <g id="Group_2">
            <g id="Clip path group">
              <mask id="mask0_1007_14489" maskUnits="userSpaceOnUse" x="31" y="12" width="52" height="94">
                <g id="clippath">
                  <path
                    id="Vector"
                    d="M80.3452 61.5469C81.6974 63.7025 82.3696 66.5736 82.3629 70.1536C82.3563 73.8047 81.4285 77.3069 79.5807 80.6591C77.7329 84.0125 74.9063 86.8825 71.0996 89.278C68.3552 91.0003 65.094 92.3147 61.3163 93.2191L61.2963 104.19L53.8752 105.234L53.8952 94.3947C49.9529 94.7114 46.1507 94.5047 42.484 93.7847C37.9618 92.8936 34.3696 91.4258 31.7207 89.3892L35.8151 78.9458C38.3463 80.7369 41.4818 82.0847 45.2229 82.9914C48.0674 83.6858 50.964 83.9547 53.9152 83.8014L53.9518 64.3503C51.9452 64.1003 49.9363 63.7869 47.9274 63.4147C45.2463 62.9092 42.8018 62.1158 40.6007 61.0258C38.3996 59.9347 36.6051 58.3103 35.224 56.1592C33.8351 54.008 33.1474 51.1147 33.1551 47.4636C33.1618 43.7503 34.0896 40.2158 35.9374 36.8625C37.7851 33.5092 40.5985 30.6336 44.374 28.2492C47.0752 26.5392 50.2918 25.2358 54.0263 24.3347L54.0463 13.3558L61.4674 12.3125L61.4474 23.1536C64.3329 22.9358 67.2007 23.0225 70.0518 23.4147C73.7852 23.9314 77.0474 24.9769 79.8218 26.5347L76.0896 36.9269C73.2552 35.5047 70.3329 34.5625 67.3129 34.1125C65.2963 33.8069 63.3351 33.6914 61.4274 33.7647L61.3907 53.488C63.4985 53.7514 65.5963 54.0647 67.6807 54.4336C70.3685 54.898 72.8052 55.6614 75.014 56.718C77.2152 57.7769 78.9885 59.3881 80.3463 61.5436L80.3452 61.5469ZM69.3152 77.4103C70.2829 75.938 70.7652 74.3592 70.7685 72.6658C70.7718 70.5736 70.0829 68.9925 68.6996 67.9225C67.3096 66.8469 65.5129 66.088 63.3107 65.6503C62.6818 65.5247 62.034 65.4069 61.3674 65.2947L61.3352 82.5725C62.6329 82.1947 63.7807 81.7436 64.7774 81.2214C66.8352 80.1536 68.3407 78.8836 69.3152 77.4103ZM53.9707 52.5203L54.0029 35.0125C52.7585 35.3981 51.6552 35.858 50.6918 36.3947C48.6707 37.5303 47.1874 38.8436 46.2485 40.3436C45.3107 41.8436 44.8429 43.4758 44.8396 45.2336C44.8363 47.3169 45.5107 48.9003 46.8652 49.9825C48.2185 51.0647 50.0007 51.8092 52.2018 52.2147C52.774 52.3203 53.3629 52.4225 53.9696 52.5203"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask0_1007_14489)">
                <g id="Group_3">
                  <path
                    id="Vector_2"
                    d="M53.9707 52.5203L54.0029 35.0125C52.7585 35.398 51.6552 35.858 50.6918 36.3947C48.6707 37.5303 47.1874 38.8436 46.2485 40.3436C45.3107 41.8436 44.8429 43.4758 44.8396 45.2336C44.8363 47.3169 45.5107 48.9003 46.8652 49.9825C48.2185 51.0647 50.0007 51.8092 52.2018 52.2147C52.774 52.3203 53.3629 52.4225 53.9696 52.5203M80.3452 61.5469C81.6974 63.7025 82.3696 66.5736 82.3629 70.1536C82.3563 73.8047 81.4285 77.3069 79.5807 80.6592C77.7329 84.0125 74.9063 86.8825 71.0996 89.278C68.3552 91.0003 65.094 92.3147 61.3163 93.2191L61.2963 104.19L53.8752 105.234L53.8952 94.3947C49.9529 94.7114 46.1507 94.5047 42.484 93.7847C37.9618 92.8936 34.3696 91.4258 31.7207 89.3892L35.8151 78.9458C38.3463 80.7369 41.4818 82.0847 45.2229 82.9914C48.0674 83.6858 50.964 83.9547 53.9152 83.8014L53.9518 64.3503C51.9452 64.1003 49.9363 63.7869 47.9274 63.4147C45.2463 62.9092 42.8018 62.1158 40.6007 61.0258C38.3996 59.9347 36.6051 58.3103 35.224 56.1592C33.8351 54.008 33.1474 51.1147 33.1551 47.4636C33.1618 43.7503 34.0896 40.2158 35.9374 36.8625C37.7851 33.5092 40.5985 30.6336 44.374 28.2492C47.0752 26.5392 50.2918 25.2358 54.0263 24.3347L54.0463 13.3558L61.4674 12.3125L61.4474 23.1536C64.3329 22.9358 67.2007 23.0225 70.0518 23.4147C73.7852 23.9314 77.0474 24.9769 79.8218 26.5347L76.0896 36.9269C73.2552 35.5047 70.3329 34.5625 67.3129 34.1125C65.2963 33.8069 63.3352 33.6914 61.4274 33.7647L61.3907 53.488C63.4985 53.7514 65.5963 54.0647 67.6807 54.4336C70.3685 54.898 72.8052 55.6614 75.014 56.718C77.2152 57.7769 78.9885 59.388 80.3463 61.5436L80.3452 61.5469ZM69.3152 77.4103C70.2829 75.938 70.7652 74.3592 70.7685 72.6658C70.7718 70.5736 70.0829 68.9925 68.6996 67.9225C67.3096 66.8469 65.5129 66.088 63.3107 65.6503C62.6818 65.5247 62.034 65.4069 61.3674 65.2947L61.3352 82.5725C62.6329 82.1947 63.7807 81.7436 64.7774 81.2214C66.8352 80.1536 68.3407 78.8836 69.3152 77.4103Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_4">
            <g id="Clip path group_2">
              <mask id="mask1_1007_14489" maskUnits="userSpaceOnUse" x="35" y="14" width="51" height="94">
                <g id="clippath-1">
                  <path
                    id="Vector_3"
                    d="M83.8901 63.4766C85.2423 65.6322 85.9145 68.5033 85.9079 72.0833C85.9012 75.7344 84.9734 79.2366 83.1256 82.5889C81.2779 85.9422 78.4512 88.8122 74.6445 91.2077C71.9001 92.93 68.639 94.2444 64.8612 95.1488L64.8412 106.12L57.4201 107.163L57.4401 96.3244C53.4978 96.6411 49.6956 96.4344 46.029 95.7144C41.5067 94.8233 37.9145 93.3555 35.2656 91.3188L39.3601 80.8755C41.8912 82.6666 45.0267 84.0144 48.7678 84.9211C51.6123 85.6155 54.509 85.8844 57.4601 85.7311L57.4967 66.28C55.4901 66.03 53.4812 65.7166 51.4723 65.3444C48.7912 64.8389 46.3467 64.0455 44.1456 62.9555C41.9445 61.8644 40.1501 60.24 38.769 58.0889C37.3801 55.9377 36.6923 53.0444 36.7001 49.3933C36.7067 45.68 37.6345 42.1455 39.4823 38.7922C41.3301 35.4389 44.1434 32.5633 47.919 30.1789C50.6201 28.4689 53.8367 27.1655 57.5712 26.2644L57.5912 15.2855L65.0123 14.2422L64.9923 25.0833C67.8778 24.8655 70.7456 24.9522 73.5967 25.3444C77.3301 25.8611 80.5923 26.9066 83.3667 28.4644L79.6345 38.8566C76.8001 37.4344 73.8778 36.4922 70.8578 36.0422C68.8412 35.7366 66.8801 35.6211 64.9723 35.6944L64.9356 55.4177C67.0434 55.6811 69.1412 55.9944 71.2256 56.3633C73.9134 56.8277 76.3501 57.5911 78.559 58.6477C80.7601 59.7066 82.5334 61.3177 83.8912 63.4733L83.8901 63.4766ZM72.8601 79.34C73.8279 77.8677 74.3101 76.2888 74.3134 74.5955C74.3167 72.5033 73.6279 70.9222 72.2445 69.8522C70.8545 68.7766 69.0579 68.0177 66.8556 67.58C66.2267 67.4544 65.579 67.3366 64.9123 67.2244L64.8801 84.5022C66.1778 84.1244 67.3256 83.6733 68.3223 83.1511C70.3801 82.0833 71.8856 80.8133 72.8601 79.34ZM57.5156 54.45L57.5478 36.9422C56.3034 37.3277 55.2001 37.7877 54.2367 38.3244C52.2156 39.46 50.7323 40.7733 49.7934 42.2733C48.8556 43.7733 48.3878 45.4055 48.3845 47.1633C48.3812 49.2466 49.0556 50.83 50.4101 51.9122C51.7634 52.9944 53.5456 53.7388 55.7467 54.1444C56.319 54.25 56.9079 54.3522 57.5145 54.45"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask1_1007_14489)">
                <g id="Group_5">
                  <path
                    id="Vector_4"
                    d="M57.5156 54.45L57.5478 36.9422C56.3034 37.3277 55.2001 37.7877 54.2367 38.3244C52.2156 39.46 50.7323 40.7733 49.7934 42.2733C48.8556 43.7733 48.3878 45.4055 48.3845 47.1633C48.3812 49.2466 49.0556 50.83 50.4101 51.9122C51.7634 52.9944 53.5456 53.7389 55.7467 54.1444C56.319 54.25 56.9079 54.3522 57.5145 54.45M83.8901 63.4766C85.2423 65.6322 85.9145 68.5033 85.9079 72.0833C85.9012 75.7344 84.9734 79.2366 83.1256 82.5888C81.2779 85.9422 78.4512 88.8122 74.6445 91.2077C71.9001 92.93 68.639 94.2444 64.8612 95.1489L64.8412 106.12L57.4201 107.163L57.4401 96.3244C53.4978 96.6411 49.6956 96.4344 46.029 95.7144C41.5067 94.8233 37.9145 93.3555 35.2656 91.3188L39.3601 80.8755C41.8912 82.6666 45.0267 84.0144 48.7679 84.9211C51.6123 85.6155 54.509 85.8844 57.4601 85.7311L57.4967 66.28C55.4901 66.03 53.4812 65.7166 51.4723 65.3444C48.7912 64.8389 46.3467 64.0455 44.1456 62.9555C41.9445 61.8644 40.1501 60.24 38.769 58.0889C37.3801 55.9377 36.6923 53.0444 36.7001 49.3933C36.7067 45.68 37.6345 42.1455 39.4823 38.7922C41.3301 35.4389 44.1434 32.5633 47.919 30.1789C50.6201 28.4689 53.8367 27.1655 57.5712 26.2644L57.5912 15.2855L65.0123 14.2422L64.9923 25.0833C67.8779 24.8655 70.7456 24.9522 73.5967 25.3444C77.3301 25.8611 80.5923 26.9066 83.3667 28.4644L79.6345 38.8566C76.8001 37.4344 73.8778 36.4922 70.8578 36.0422C68.8412 35.7366 66.8801 35.6211 64.9723 35.6944L64.9356 55.4177C67.0434 55.6811 69.1412 55.9944 71.2256 56.3633C73.9134 56.8277 76.3501 57.5911 78.559 58.6477C80.7601 59.7066 82.5334 61.3177 83.8912 63.4733L83.8901 63.4766ZM72.8601 79.34C73.8279 77.8677 74.3101 76.2888 74.3134 74.5955C74.3167 72.5033 73.6279 70.9222 72.2445 69.8522C70.8545 68.7766 69.0579 68.0177 66.8556 67.58C66.2267 67.4544 65.579 67.3366 64.9123 67.2244L64.8801 84.5022C66.1778 84.1244 67.3256 83.6733 68.3223 83.1511C70.3801 82.0833 71.8856 80.8133 72.8601 79.34Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_6">
            <g id="Clip path group_3">
              <mask id="mask2_1007_14489" maskUnits="userSpaceOnUse" x="61" y="61" width="25" height="35">
                <g id="clippath-2">
                  <path
                    id="Vector_5"
                    d="M83.8909 63.4759C85.2431 65.6314 85.9153 68.5026 85.9086 72.0826C85.902 75.7337 84.9742 79.2359 83.1264 82.5881C81.2786 85.9414 78.452 88.8114 74.6453 91.207C71.9009 92.9292 68.6397 94.2437 64.862 95.1481L61.3164 93.2192C65.0942 92.3148 68.3553 90.9992 71.0997 89.2781C74.9064 86.8826 77.7331 84.0126 79.5809 80.6592C81.4286 77.3059 82.3564 73.8037 82.3631 70.1537C82.3697 66.5748 81.6975 63.7026 80.3453 61.547L83.8909 63.4759Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask2_1007_14489)">
                <g id="Group_7">
                  <g id="Group_8">
                    <g id="Group_9">
                      <path
                        id="Vector_6"
                        d="M83.8893 63.4759L80.3438 61.547C81.696 63.7026 82.3682 66.5737 82.3615 70.1537L85.9071 72.0826C85.9138 68.5037 85.2415 65.6314 83.8893 63.4759Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_7"
                        d="M85.0143 65.8665L81.4688 63.9376"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_8"
                        d="M85.6862 68.7415L82.1406 66.8126"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_10">
                      <path
                        id="Vector_9"
                        d="M85.9079 72.0853L82.3623 70.1564C82.3556 73.8075 81.4279 77.3097 79.5801 80.6619L83.1256 82.5908C84.9734 79.2375 85.9012 75.7353 85.9079 72.0853Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_10"
                        d="M85.5944 75.6868L82.0488 73.7579"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_11"
                        d="M84.6667 79.1867L81.1211 77.2578"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_11">
                      <path
                        id="Vector_12"
                        d="M83.1263 82.5929L79.5807 80.6641C77.7329 84.0174 74.9063 86.8874 71.0996 89.283L74.6452 91.2118C78.4518 88.8163 81.2785 85.9463 83.1263 82.5929Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_13"
                        d="M80.9518 85.7883L77.4062 83.8594"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_14"
                        d="M78.1237 88.6554L74.5781 86.7266"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_12">
                      <path
                        id="Vector_15"
                        d="M74.6433 91.2101L71.0978 89.2812C68.3533 91.0035 65.0922 92.3179 61.3145 93.2224L64.86 95.1513C68.6378 94.2468 71.8989 92.9312 74.6433 91.2101Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_16"
                        d="M71.7272 92.7961L68.1816 90.8672"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_17"
                        d="M68.4655 94.1086L64.9199 92.1797"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_18"
                    d="M83.8909 63.4759C85.2431 65.6314 85.9153 68.5026 85.9086 72.0826C85.902 75.7337 84.9742 79.2359 83.1264 82.5881C81.2786 85.9414 78.452 88.8114 74.6453 91.207C71.9009 92.9292 68.6397 94.2437 64.862 95.1481L61.3164 93.2192C65.0942 92.3148 68.3553 90.9992 71.0997 89.2781C74.9064 86.8826 77.7331 84.0126 79.5809 80.6592C81.4286 77.3059 82.3564 73.8037 82.3631 70.1537C82.3697 66.5748 81.6975 63.7026 80.3453 61.547L83.8909 63.4759Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_13">
            <g id="Clip path group_4">
              <mask id="mask3_1007_14489" maskUnits="userSpaceOnUse" x="61" y="93" width="4" height="14">
                <g id="clippath-3">
                  <path
                    id="Vector_19"
                    d="M64.8605 95.1476L64.8405 106.119L61.2949 104.19L61.3149 93.2188L64.8605 95.1476Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask3_1007_14489)">
                <g id="Group_14">
                  <path
                    id="Vector_20"
                    d="M64.8605 95.1476L64.8405 106.119L61.2949 104.19L61.3149 93.2188L64.8605 95.1476Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_15">
            <g id="Clip path group_5">
              <mask id="mask4_1007_14489" maskUnits="userSpaceOnUse" x="53" y="104" width="12" height="4">
                <g id="clippath-4">
                  <path
                    id="Vector_21"
                    d="M64.8408 106.116L57.4186 107.16L53.873 105.231L61.2953 104.188L64.8408 106.116Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask4_1007_14489)">
                <g id="Group_16">
                  <path
                    id="Vector_22"
                    d="M64.8408 106.116L57.4186 107.16L53.873 105.231L61.2953 104.188L64.8408 106.116Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_17">
            <g id="Clip path group_6">
              <mask id="mask5_1007_14489" maskUnits="userSpaceOnUse" x="53" y="94" width="5" height="14">
                <g id="clippath-5">
                  <path
                    id="Vector_23"
                    d="M57.4186 107.166L53.873 105.237L53.893 94.3984L57.4386 96.3273L57.4186 107.166Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask5_1007_14489)">
                <g id="Group_18">
                  <path
                    id="Vector_24"
                    d="M57.4186 107.166L53.873 105.237L53.893 94.3984L57.4386 96.3273L57.4186 107.166Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_19">
            <g id="Clip path group_7">
              <mask id="mask6_1007_14489" maskUnits="userSpaceOnUse" x="31" y="89" width="7" height="4">
                <g id="clippath-6">
                  <path
                    id="Vector_25"
                    d="M37.6665 92.8729L34.121 90.944C33.2487 90.4695 32.4488 89.9517 31.7188 89.3906L35.2643 91.3195C35.9932 91.8806 36.7943 92.3984 37.6665 92.8729Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask6_1007_14489)">
                <g id="Group_20">
                  <g id="Group_21">
                    <path
                      id="Vector_26"
                      d="M46.0276 95.7151L42.4821 93.7862C37.9599 92.8951 34.3676 91.4273 31.7188 89.3906L35.2643 91.3195C37.9132 93.3573 41.5054 94.824 46.0276 95.7151Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_27"
                    d="M37.6665 92.8729L34.121 90.944C33.2487 90.4695 32.4488 89.9517 31.7188 89.3906L35.2643 91.3195C35.9932 91.8806 36.7943 92.3984 37.6665 92.8729Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_22">
            <g id="Clip path group_8">
              <mask id="mask7_1007_14489" maskUnits="userSpaceOnUse" x="34" y="90" width="24" height="7">
                <g id="clippath-7">
                  <path
                    id="Vector_28"
                    d="M34.1211 90.9453C36.4166 92.1942 39.2044 93.142 42.4822 93.7886C46.1489 94.5098 49.9511 94.7164 53.8933 94.3986L57.4389 96.3275C53.4967 96.6442 49.6944 96.4375 46.0278 95.7175C42.75 95.0709 39.9622 94.1231 37.6667 92.8742C36.6033 92.2953 35.1844 91.5242 34.1211 90.9453Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask7_1007_14489)">
                <g id="Group_23">
                  <g id="Group_24">
                    <g id="Group_25">
                      <path
                        id="Vector_29"
                        d="M57.4391 96.3279L53.8935 94.3991C49.9513 94.7157 46.1491 94.5091 42.4824 93.7891L46.028 95.7179C49.6946 96.4391 53.4969 96.6457 57.4391 96.3279Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path id="Vector_30" d="M53.5436 96.468L49.998 94.5391" stroke="#E6EDF3" stroke-miterlimit="10" />
                      <path
                        id="Vector_31"
                        d="M49.7409 96.2648L46.1953 94.3359"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_26">
                      <path
                        id="Vector_32"
                        d="M46.0276 95.7151L42.4821 93.7862C37.9599 92.8951 34.3676 91.4273 31.7188 89.3906L35.2643 91.3195C37.9132 93.3573 41.5054 94.824 46.0276 95.7151Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path id="Vector_33" d="M41.817 94.632L38.2715 92.7031" stroke="#E6EDF3" stroke-miterlimit="10" />
                      <path
                        id="Vector_34"
                        d="M38.2272 93.1633L34.6816 91.2344"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_35"
                    d="M34.1211 90.9453C36.4166 92.1942 39.2044 93.142 42.4822 93.7886C46.1489 94.5098 49.9511 94.7164 53.8933 94.3986L57.4389 96.3275C53.4967 96.6442 49.6944 96.4375 46.0278 95.7175C42.75 95.0709 39.9622 94.1231 37.6667 92.8742C36.6033 92.2953 35.1844 91.5242 34.1211 90.9453Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_27">
            <g id="Clip path group_9">
              <mask id="mask8_1007_14489" maskUnits="userSpaceOnUse" x="31" y="78" width="9" height="14">
                <g id="clippath-8">
                  <path
                    id="Vector_36"
                    d="M35.2643 91.3175L31.7188 89.3886L35.8132 78.9453L39.3588 80.8742L35.2643 91.3175Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask8_1007_14489)">
                <g id="Group_28">
                  <path
                    id="Vector_37"
                    d="M35.2643 91.3175L31.7188 89.3886L35.8132 78.9453L39.3588 80.8742L35.2643 91.3175Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_29">
            <g id="Clip path group_10">
              <mask id="mask9_1007_14489" maskUnits="userSpaceOnUse" x="37" y="80" width="21" height="6">
                <g id="clippath-9">
                  <path
                    id="Vector_38"
                    d="M41.526 82.2179L37.9805 80.2891C40.0694 81.4257 42.4827 82.3257 45.2194 82.9879C48.0638 83.6824 50.9605 83.9513 53.9116 83.798L57.4571 85.7268C54.506 85.8802 51.6094 85.6113 48.7649 84.9168C46.0283 84.2535 43.6149 83.3546 41.526 82.2179Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask9_1007_14489)">
                <g id="Group_30">
                  <g id="Group_31">
                    <g id="Group_32">
                      <path
                        id="Vector_39"
                        d="M39.3581 80.8742L35.8125 78.9453C38.3436 80.7364 41.4792 82.0842 45.2203 82.9909L48.7658 84.9198C45.0247 84.0131 41.8892 82.6653 39.3581 80.8742Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_40"
                        d="M42.0905 82.5148L38.5449 80.5859"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_41"
                        d="M45.2252 83.8664L41.6797 81.9375"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_33">
                      <path
                        id="Vector_42"
                        d="M48.7663 84.9211L45.2207 82.9922C48.0651 83.6866 50.9618 83.9555 53.9129 83.8022L57.4585 85.7311C54.5074 85.8844 51.6107 85.6155 48.7663 84.9211Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_43"
                        d="M51.6276 85.4758L48.082 83.5469"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_44"
                        d="M54.526 85.7414L50.9805 83.8125"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_45"
                    d="M41.526 82.2179L37.9805 80.2891C40.0694 81.4257 42.4827 82.3257 45.2194 82.9879C48.0638 83.6824 50.9605 83.9513 53.9116 83.798L57.4571 85.7268C54.506 85.8802 51.6094 85.6113 48.7649 84.9168C46.0283 84.2535 43.6149 83.3546 41.526 82.2179Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_34">
            <g id="Clip path group_11">
              <mask id="mask10_1007_14489" maskUnits="userSpaceOnUse" x="35" y="78" width="7" height="5">
                <g id="clippath-10">
                  <path
                    id="Vector_46"
                    d="M37.9803 80.292C37.2136 79.8753 36.4914 79.4264 35.8125 78.9453L39.3581 80.8742C40.0369 81.3553 40.7603 81.8031 41.5258 82.2209C40.4625 81.642 39.0436 80.8709 37.9803 80.292Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask10_1007_14489)">
                <g id="Group_35">
                  <g id="Group_36">
                    <path
                      id="Vector_47"
                      d="M39.3581 80.8742L35.8125 78.9453C38.3436 80.7364 41.4792 82.0842 45.2203 82.9909L48.7658 84.9198C45.0247 84.0131 41.8892 82.6653 39.3581 80.8742Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_48"
                    d="M37.9803 80.292C37.2136 79.8753 36.4914 79.4264 35.8125 78.9453L39.3581 80.8742C40.0369 81.3553 40.7603 81.8031 41.5258 82.2209C40.4625 81.642 39.0436 80.8709 37.9803 80.292Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_37">
            <g id="Clip path group_12">
              <mask id="mask11_1007_14489" maskUnits="userSpaceOnUse" x="53" y="64" width="5" height="22">
                <g id="clippath-11">
                  <path
                    id="Vector_49"
                    d="M57.4596 85.7316L53.9141 83.8038L53.9507 64.3516L57.4963 66.2804L57.4596 85.7316Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask11_1007_14489)">
                <g id="Group_38">
                  <path
                    id="Vector_50"
                    d="M57.4596 85.7316L53.9141 83.8038L53.9507 64.3516L57.4963 66.2804L57.4596 85.7316Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_39">
            <g id="Clip path group_13">
              <mask id="mask12_1007_14489" maskUnits="userSpaceOnUse" x="33" y="24" width="25" height="39">
                <g id="clippath-12">
                  <path
                    id="Vector_51"
                    d="M43.6835 62.717L40.138 60.7882C38.1457 59.7048 36.5046 58.1604 35.2213 56.1604C33.8324 54.0093 33.1446 51.1159 33.1524 47.4648C33.1591 43.7515 34.0869 40.2171 35.9346 36.8637C37.7824 33.5104 40.5957 30.6348 44.3713 28.2504C47.0724 26.5404 50.2891 25.237 54.0235 24.3359L57.5691 26.2648C53.8346 27.167 50.6191 28.4693 47.9169 30.1793C44.1402 32.5637 41.328 35.4393 39.4802 38.7926C37.6324 42.1459 36.7046 45.6793 36.698 49.3937C36.6913 53.0448 37.3791 55.9382 38.7669 58.0893C40.0502 60.0882 41.6902 61.6326 43.6835 62.717Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask12_1007_14489)">
                <g id="Group_40">
                  <g id="Group_41">
                    <g id="Group_42">
                      <path
                        id="Vector_52"
                        d="M44.1429 62.9596L40.5974 61.0307C38.3963 59.9396 36.6018 58.3152 35.2207 56.1641L38.7663 58.0929C40.1474 60.2441 41.9418 61.8685 44.1429 62.9596Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_53"
                        d="M42.0768 61.6945L38.5312 59.7656"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_54"
                        d="M40.2858 60.0695L36.7402 58.1406"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_43">
                      <path
                        id="Vector_55"
                        d="M38.7688 58.0854L35.2233 56.1565C33.8344 54.0054 33.1466 51.112 33.1544 47.4609L36.6999 49.3898C36.6933 53.0409 37.381 55.9343 38.7688 58.0854Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_56"
                        d="M37.6139 55.6867L34.0684 53.7578"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_57"
                        d="M36.9245 52.7872L33.3789 50.8594"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_44">
                      <path
                        id="Vector_58"
                        d="M36.6979 49.3972L33.1523 47.4683C33.159 43.755 34.0868 40.2205 35.9346 36.8672L39.4801 38.7961C37.6323 42.1494 36.7046 45.6827 36.6979 49.3972Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_59"
                        d="M37.0123 45.7414L33.4668 43.8125"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_60"
                        d="M37.9381 42.2101L34.3926 40.2812"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_45">
                      <path
                        id="Vector_61"
                        d="M39.4811 38.7922L35.9355 36.8633C37.7833 33.51 40.5967 30.6344 44.3722 28.25L47.9178 30.1789C44.1411 32.5633 41.3289 35.4389 39.4811 38.7922Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_62"
                        d="M41.6491 35.6008L38.1035 33.6719"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_63"
                        d="M44.4616 32.7258L40.916 30.7969"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_46">
                      <path
                        id="Vector_64"
                        d="M47.9186 30.1793L44.373 28.2504C47.0742 26.5404 50.2908 25.237 54.0253 24.3359L57.5708 26.2648C53.8364 27.167 50.6208 28.4693 47.9186 30.1793Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_65"
                        d="M50.7916 28.6086L47.2461 26.6797"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_66"
                        d="M54.0084 27.3039L50.4629 25.375"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_67"
                    d="M43.6835 62.717L40.138 60.7882C38.1457 59.7048 36.5046 58.1604 35.2213 56.1604C33.8324 54.0093 33.1446 51.1159 33.1524 47.4648C33.1591 43.7515 34.0869 40.2171 35.9346 36.8637C37.7824 33.5104 40.5957 30.6348 44.3713 28.2504C47.0724 26.5404 50.2891 25.237 54.0235 24.3359L57.5691 26.2648C53.8346 27.167 50.6191 28.4693 47.9169 30.1793C44.1402 32.5637 41.328 35.4393 39.4802 38.7926C37.6324 42.1459 36.7046 45.6793 36.698 49.3937C36.6913 53.0448 37.3791 55.9382 38.7669 58.0893C40.0502 60.0882 41.6902 61.6326 43.6835 62.717Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_47">
            <g id="Clip path group_14">
              <mask id="mask13_1007_14489" maskUnits="userSpaceOnUse" x="40" y="60" width="18" height="7">
                <g id="clippath-13">
                  <path
                    id="Vector_68"
                    d="M40.1367 60.7891C40.2878 60.8713 40.4412 60.9513 40.5967 61.0279C42.7978 62.1179 45.2423 62.9113 47.9234 63.4168C49.9323 63.7891 51.9423 64.1024 53.9478 64.3524L57.4934 66.2813C55.4867 66.0313 53.4778 65.718 51.4689 65.3457C48.7878 64.8402 46.3434 64.0468 44.1423 62.9568C43.9867 62.8802 43.8334 62.8002 43.6823 62.7179C42.6189 62.1391 41.2001 61.368 40.1367 60.7891Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask13_1007_14489)">
                <g id="Group_48">
                  <g id="Group_49">
                    <g id="Group_50">
                      <path
                        id="Vector_69"
                        d="M57.4958 66.2863L53.9502 64.3574C51.9436 64.1074 49.9347 63.7941 47.9258 63.4219L51.4713 65.3508C53.4802 65.723 55.4902 66.0363 57.4958 66.2863Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_70"
                        d="M55.4889 66.0148L51.9434 64.0859"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_71"
                        d="M53.4791 65.7012L49.9336 63.7734"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_51">
                      <path
                        id="Vector_72"
                        d="M51.4699 65.349L47.9243 63.4201C45.2432 62.9146 42.7988 62.1213 40.5977 61.0312L44.1432 62.9601C46.3443 64.0501 48.7888 64.8435 51.4699 65.349Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_73"
                        d="M48.8689 64.7492L45.3223 62.8203"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_74"
                        d="M46.4245 63.9523L42.8789 62.0234"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <path
                      id="Vector_75"
                      d="M44.1429 62.9596L40.5974 61.0307C38.3963 59.9396 36.6018 58.3152 35.2207 56.1641L38.7663 58.0929C40.1474 60.2441 41.9418 61.8685 44.1429 62.9596Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_76"
                    d="M40.1367 60.7891C40.2878 60.8713 40.4412 60.9513 40.5967 61.0279C42.7978 62.1179 45.2423 62.9113 47.9234 63.4168C49.9323 63.7891 51.9423 64.1024 53.9478 64.3524L57.4934 66.2813C55.4867 66.0313 53.4778 65.718 51.4689 65.3457C48.7878 64.8402 46.3434 64.0468 44.1423 62.9568C43.9867 62.8802 43.8334 62.8002 43.6823 62.7179C42.6189 62.1391 41.2001 61.368 40.1367 60.7891Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_52">
            <g id="Clip path group_15">
              <mask id="mask14_1007_14489" maskUnits="userSpaceOnUse" x="54" y="13" width="4" height="14">
                <g id="clippath-14">
                  <path
                    id="Vector_77"
                    d="M57.569 26.2672L54.0234 24.3383L54.0434 13.3594L57.589 15.2883L57.569 26.2672Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask14_1007_14489)">
                <g id="Group_53">
                  <path
                    id="Vector_78"
                    d="M57.569 26.2672L54.0234 24.3383L54.0434 13.3594L57.589 15.2883L57.569 26.2672Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_54">
            <g id="Clip path group_16">
              <mask id="mask15_1007_14489" maskUnits="userSpaceOnUse" x="54" y="12" width="12" height="4">
                <g id="clippath-15">
                  <path
                    id="Vector_79"
                    d="M57.5905 15.2836L54.0449 13.3547L61.466 12.3125L65.0116 14.2403L57.5905 15.2836Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask15_1007_14489)">
                <g id="Group_55">
                  <path
                    id="Vector_80"
                    d="M57.5905 15.2836L54.0449 13.3547L61.466 12.3125L65.0116 14.2403L57.5905 15.2836Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_56">
            <g id="Clip path group_17">
              <mask id="mask16_1007_14489" maskUnits="userSpaceOnUse" x="61" y="12" width="5" height="14">
                <g id="clippath-16">
                  <path
                    id="Vector_81"
                    d="M65.0109 14.2403L64.9909 25.0825L61.4453 23.1536L61.4653 12.3125L65.0109 14.2403Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask16_1007_14489)">
                <g id="Group_57">
                  <path
                    id="Vector_82"
                    d="M65.0109 14.2403L64.9909 25.0825L61.4453 23.1536L61.4653 12.3125L65.0109 14.2403Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_58">
            <g id="Clip path group_18">
              <mask id="mask17_1007_14489" maskUnits="userSpaceOnUse" x="79" y="26" width="5" height="3">
                <g id="clippath-17">
                  <path
                    id="Vector_83"
                    d="M83.1022 28.3195C83.1911 28.3673 83.2789 28.4162 83.3666 28.4651L79.8211 26.5362C79.7333 26.4873 79.6455 26.4384 79.5566 26.3906C80.62 26.9695 82.0389 27.7406 83.1022 28.3195Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask17_1007_14489)">
                <g id="Group_59">
                  <g id="Group_60">
                    <path
                      id="Vector_84"
                      d="M73.5963 25.343L70.0508 23.4141C73.7841 23.9307 77.0463 24.9763 79.8208 26.5341L83.3663 28.4629C80.5919 26.9041 77.3297 25.8596 73.5963 25.343Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_85"
                    d="M83.1022 28.3195C83.1911 28.3673 83.2789 28.4162 83.3666 28.4651L79.8211 26.5362C79.7333 26.4873 79.6455 26.4384 79.5566 26.3906C80.62 26.9695 82.0389 27.7406 83.1022 28.3195Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_61">
            <g id="Clip path group_19">
              <mask id="mask18_1007_14489" maskUnits="userSpaceOnUse" x="61" y="23" width="23" height="6">
                <g id="clippath-18">
                  <path
                    id="Vector_86"
                    d="M79.5564 26.3914L83.102 28.3203C80.3853 26.8425 77.2109 25.847 73.5953 25.3458C70.7442 24.9536 67.8764 24.867 64.9909 25.0847L61.4453 23.1558C64.3309 22.9381 67.1986 23.0247 70.0498 23.417C73.6653 23.9181 76.8386 24.9136 79.5564 26.3914Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask18_1007_14489)">
                <g id="Group_62">
                  <g id="Group_63">
                    <g id="Group_64">
                      <path
                        id="Vector_87"
                        d="M64.9909 25.0847L61.4453 23.1558C64.3309 22.9381 67.1986 23.0247 70.0498 23.417L73.5953 25.3458C70.7442 24.9536 67.8764 24.867 64.9909 25.0847Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_88"
                        d="M67.8717 24.968L64.3262 23.0391"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_89"
                        d="M70.7389 25.0539L67.1934 23.125"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_65">
                      <path
                        id="Vector_90"
                        d="M73.5944 25.343L70.0488 23.4141C73.7822 23.9307 77.0444 24.9763 79.8188 26.5341L83.3644 28.4629C80.5899 26.9041 77.3277 25.8596 73.5944 25.343Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_91"
                        d="M77.1706 26.0383L73.625 24.1094"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_92"
                        d="M80.4284 27.0773L76.8828 25.1484"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_93"
                    d="M79.5564 26.3914L83.102 28.3203C80.3853 26.8425 77.2109 25.847 73.5953 25.3458C70.7442 24.9536 67.8764 24.867 64.9909 25.0847L61.4453 23.1558C64.3309 22.9381 67.1986 23.0247 70.0498 23.417C73.6653 23.9181 76.8386 24.9136 79.5564 26.3914Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_66">
            <g id="Clip path group_20">
              <mask id="mask19_1007_14489" maskUnits="userSpaceOnUse" x="76" y="26" width="8" height="13">
                <g id="clippath-19">
                  <path
                    id="Vector_94"
                    d="M83.3657 28.468L79.6334 38.8602L76.0879 36.9313L79.8201 26.5391L83.3657 28.468Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask19_1007_14489)">
                <g id="Group_67">
                  <path
                    id="Vector_95"
                    d="M83.3657 28.468L79.6334 38.8602L76.0879 36.9313L79.8201 26.5391L83.3657 28.468Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_68">
            <g id="Clip path group_21">
              <mask id="mask20_1007_14489" maskUnits="userSpaceOnUse" x="61" y="33" width="19" height="6">
                <g id="clippath-20">
                  <path
                    id="Vector_96"
                    d="M79.6336 38.8547C76.7991 37.4325 73.8769 36.4903 70.8569 36.0403C68.8402 35.7347 66.8791 35.6192 64.9713 35.6925L61.4258 33.7636C63.3336 33.6903 65.2947 33.8047 67.3113 34.1114C70.3313 34.5614 73.2547 35.5036 76.088 36.9259C77.1513 37.5047 78.5702 38.2759 79.6336 38.8547Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask20_1007_14489)">
                <g id="Group_69">
                  <g id="Group_70">
                    <g id="Group_71">
                      <path
                        id="Vector_97"
                        d="M79.6328 38.8527L76.0872 36.9238C73.2528 35.5016 70.3305 34.5594 67.3105 34.1094L70.8561 36.0383C73.8761 36.4883 76.7994 37.4305 79.6328 38.8527Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_98"
                        d="M76.7682 37.5929L73.2227 35.6641"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_99"
                        d="M73.8424 36.6555L70.2969 34.7266"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_72">
                      <path
                        id="Vector_100"
                        d="M70.8549 36.0402L67.3094 34.1113C65.2927 33.8058 63.3316 33.6902 61.4238 33.7635L64.9694 35.6924C66.8772 35.6191 68.8383 35.7335 70.8549 36.0402Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_101"
                        d="M68.8581 35.7961L65.3125 33.8672"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_102"
                        d="M66.8952 35.6789L63.3496 33.75"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_103"
                    d="M79.6336 38.8547C76.7991 37.4325 73.8769 36.4903 70.8569 36.0403C68.8402 35.7347 66.8791 35.6192 64.9713 35.6925L61.4258 33.7636C63.3336 33.6903 65.2947 33.8047 67.3113 34.1114C70.3313 34.5614 73.2547 35.5036 76.088 36.9259C77.1513 37.5047 78.5702 38.2759 79.6336 38.8547Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_73">
            <g id="Clip path group_22">
              <mask id="mask21_1007_14489" maskUnits="userSpaceOnUse" x="61" y="33" width="4" height="23">
                <g id="clippath-21">
                  <path
                    id="Vector_104"
                    d="M64.9709 35.6945L64.9342 55.419L61.3887 53.4901L61.4253 33.7656L64.9709 35.6945Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask21_1007_14489)">
                <g id="Group_74">
                  <path
                    id="Vector_105"
                    d="M64.9709 35.6945L64.9342 55.419L61.3887 53.4901L61.4253 33.7656L64.9709 35.6945Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_75">
            <g id="Clip path group_23">
              <mask id="mask22_1007_14489" maskUnits="userSpaceOnUse" x="75" y="57" width="9" height="7">
                <g id="clippath-22">
                  <path
                    id="Vector_106"
                    d="M79.1295 58.9445C81.0673 59.999 82.6507 61.5112 83.8895 63.4767L80.344 61.5478C79.1051 59.5823 77.5218 58.0701 75.584 57.0156C76.6473 57.5945 78.0662 58.3656 79.1295 58.9445Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask22_1007_14489)">
                <g id="Group_76">
                  <g id="Group_77">
                    <g id="Group_78">
                      <path
                        id="Vector_107"
                        d="M78.5573 58.6476L75.0117 56.7188C77.2128 57.7776 78.9862 59.3887 80.3439 61.5443L83.8895 63.4732C82.5306 61.3176 80.7584 59.7065 78.5573 58.6476Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_108"
                        d="M80.6159 59.8898L77.0703 57.9609"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_109"
                        d="M82.3932 61.4992L78.8477 59.5703"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_110"
                    d="M79.1295 58.9445C81.0673 59.999 82.6507 61.5112 83.8895 63.4767L80.344 61.5478C79.1051 59.5823 77.5218 58.0701 75.584 57.0156C76.6473 57.5945 78.0662 58.3656 79.1295 58.9445Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_79">
            <g id="Clip path group_24">
              <mask id="mask23_1007_14489" maskUnits="userSpaceOnUse" x="61" y="53" width="19" height="6">
                <g id="clippath-23">
                  <path
                    id="Vector_111"
                    d="M75.5842 57.0155L79.1298 58.9444C78.942 58.8422 78.7509 58.7444 78.5576 58.6511C76.3498 57.5944 73.9131 56.8311 71.2242 56.3666C69.1398 55.9977 67.042 55.6844 64.9342 55.4211L61.3887 53.4922C63.4964 53.7555 65.5942 54.0689 67.6787 54.4377C70.3664 54.9022 72.8031 55.6655 75.012 56.7222C75.2064 56.8155 75.3965 56.9133 75.5842 57.0155Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask23_1007_14489)">
                <g id="Group_80">
                  <g id="Group_81">
                    <g id="Group_82">
                      <path
                        id="Vector_112"
                        d="M64.9342 55.4211L61.3887 53.4922C63.4965 53.7555 65.5942 54.0689 67.6787 54.4377L71.2242 56.3666C69.1398 55.9977 67.042 55.6844 64.9342 55.4211Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_113"
                        d="M67.0377 55.7023L63.4922 53.7734"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_114"
                        d="M69.1354 56.0137L65.5898 54.0859"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_83">
                      <path
                        id="Vector_115"
                        d="M71.2233 56.3664L67.6777 54.4375C70.3655 54.9019 72.8022 55.6653 75.0111 56.7219L78.5566 58.6508C76.3488 57.5942 73.9122 56.8308 71.2233 56.3664Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path id="Vector_116" d="M73.8288 56.9289L70.2832 55" stroke="#E6EDF3" stroke-miterlimit="10" />
                      <path
                        id="Vector_117"
                        d="M76.2702 57.6945L72.7246 55.7656"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <path
                      id="Vector_118"
                      d="M78.5573 58.6476L75.0117 56.7188C77.2128 57.7776 78.9862 59.3887 80.3439 61.5443L83.8895 63.4732C82.5306 61.3176 80.7584 59.7065 78.5573 58.6476Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_119"
                    d="M75.5842 57.0155L79.1298 58.9444C78.942 58.8422 78.7509 58.7444 78.5576 58.6511C76.3498 57.5944 73.9131 56.8311 71.2242 56.3666C69.1398 55.9977 67.042 55.6844 64.9342 55.4211L61.3887 53.4922C63.4964 53.7555 65.5942 54.0689 67.6787 54.4377C70.3664 54.9022 72.8031 55.6655 75.012 56.7222C75.2064 56.8155 75.3965 56.9133 75.5842 57.0155Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_84">
            <g id="Clip path group_25">
              <mask id="mask24_1007_14489" maskUnits="userSpaceOnUse" x="61" y="65" width="10" height="4">
                <g id="clippath-24">
                  <path
                    id="Vector_120"
                    d="M70.9016 68.9802C69.7539 68.3558 68.4061 67.8891 66.8561 67.5813C66.2272 67.4558 65.5794 67.338 64.9127 67.2258L61.3672 65.2969C62.035 65.4091 62.6827 65.5269 63.3105 65.6524C64.8605 65.9602 66.2083 66.4269 67.3561 67.0513C68.4194 67.6302 69.8383 68.4013 70.9016 68.9802Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask24_1007_14489)">
                <g id="Group_85">
                  <g id="Group_86">
                    <g id="Group_87">
                      <path
                        id="Vector_121"
                        d="M72.245 69.8574L68.6994 67.9285C67.3094 66.8529 65.5128 66.094 63.3105 65.6562L66.8561 67.5851C69.0583 68.0229 70.855 68.7807 72.245 69.8574Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_122"
                        d="M70.7194 68.8898L67.1738 66.9609"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_123"
                        d="M68.9225 68.132L65.377 66.2031"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_88">
                      <path
                        id="Vector_124"
                        d="M66.8561 67.5813L63.3105 65.6524C62.6816 65.5269 62.0339 65.4091 61.3672 65.2969L64.9127 67.2258C65.5805 67.338 66.2283 67.4558 66.8561 67.5813Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_125"
                        d="M66.2213 67.4601L62.6758 65.5312"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_126"
                        d="M65.5729 67.3429L62.0273 65.4141"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_127"
                    d="M70.9016 68.9802C69.7539 68.3558 68.4061 67.8891 66.8561 67.5813C66.2272 67.4558 65.5794 67.338 64.9127 67.2258L61.3672 65.2969C62.035 65.4091 62.6827 65.5269 63.3105 65.6524C64.8605 65.9602 66.2083 66.4269 67.3561 67.0513C68.4194 67.6302 69.8383 68.4013 70.9016 68.9802Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_89">
            <g id="Clip path group_26">
              <mask id="mask25_1007_14489" maskUnits="userSpaceOnUse" x="67" y="67" width="8" height="13">
                <g id="clippath-25">
                  <path
                    id="Vector_128"
                    d="M67.3574 67.0469L70.903 68.9758C71.3863 69.2391 71.8341 69.5302 72.2463 69.8491C73.6296 70.9191 74.3196 72.5002 74.3152 74.5924C74.3119 76.2858 73.8308 77.8647 72.8619 79.3369L69.3163 77.408C70.2841 75.9358 70.7663 74.3569 70.7696 72.6635C70.773 70.5713 70.0841 68.9902 68.7008 67.9202C68.2885 67.6013 67.8408 67.3102 67.3574 67.0469Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask25_1007_14489)">
                <g id="Group_90">
                  <g id="Group_91">
                    <g id="Group_92">
                      <path
                        id="Vector_129"
                        d="M72.862 79.3374L69.3164 77.4085C70.2842 75.9363 70.7664 74.3574 70.7697 72.6641L74.3153 74.5929C74.312 76.2863 73.8308 77.8652 72.862 79.3374Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_130"
                        d="M73.6686 77.8273L70.123 75.8984"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_131"
                        d="M74.151 76.2492L70.6055 74.3203"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_93">
                      <path
                        id="Vector_132"
                        d="M74.3156 74.5941L70.7701 72.6652C70.7734 70.573 70.0845 68.9919 68.7012 67.9219L72.2467 69.8508C73.6301 70.9208 74.3201 72.5019 74.3156 74.5941Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_133"
                        d="M74.0885 72.6711L70.543 70.7422"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_134"
                        d="M73.3991 71.093L69.8535 69.1641"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <path
                      id="Vector_135"
                      d="M72.2469 69.8495L68.7014 67.9207C67.3114 66.8451 65.5147 66.0862 63.3125 65.6484L66.8581 67.5773C69.0603 68.0151 70.8569 68.7729 72.2469 69.8495Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_136"
                    d="M67.3574 67.0469L70.903 68.9758C71.3863 69.2391 71.8341 69.5302 72.2463 69.8491C73.6296 70.9191 74.3196 72.5002 74.3152 74.5924C74.3119 76.2858 73.8308 77.8647 72.8619 79.3369L69.3163 77.408C70.2841 75.9358 70.7663 74.3569 70.7696 72.6635C70.773 70.5713 70.0841 68.9902 68.7008 67.9202C68.2885 67.6013 67.8408 67.3102 67.3574 67.0469Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_94">
            <g id="Clip path group_27">
              <mask id="mask26_1007_14489" maskUnits="userSpaceOnUse" x="61" y="65" width="4" height="20">
                <g id="clippath-26">
                  <path
                    id="Vector_137"
                    d="M64.9137 67.2258L64.8815 84.5035L61.3359 82.5747L61.3682 65.2969L64.9137 67.2258Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask26_1007_14489)">
                <g id="Group_95">
                  <path
                    id="Vector_138"
                    d="M64.9137 67.2258L64.8815 84.5035L61.3359 82.5747L61.3682 65.2969L64.9137 67.2258Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_96">
            <g id="Clip path group_28">
              <mask id="mask27_1007_14489" maskUnits="userSpaceOnUse" x="61" y="77" width="12" height="8">
                <g id="clippath-27">
                  <path
                    id="Vector_139"
                    d="M64.8815 84.5052L61.3359 82.5763C62.6337 82.1985 63.7815 81.7474 64.7782 81.2252C66.8359 80.1574 68.3415 78.8874 69.3159 77.4141L72.8615 79.3429C71.887 80.8163 70.3815 82.0852 68.3237 83.1541C67.327 83.6774 66.1793 84.1274 64.8815 84.5052Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask27_1007_14489)">
                <g id="Group_97">
                  <g id="Group_98">
                    <g id="Group_99">
                      <path
                        id="Vector_140"
                        d="M64.8815 84.5066L61.3359 82.5777C62.6337 82.1999 63.7815 81.7488 64.7782 81.2266L68.3237 83.1554C67.327 83.6788 66.1793 84.1288 64.8815 84.5066Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_141"
                        d="M66.1295 84.1008L62.584 82.1719"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_142"
                        d="M67.278 83.6555L63.7324 81.7266"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_100">
                      <path
                        id="Vector_143"
                        d="M68.3229 83.1541L64.7773 81.2252C66.8351 80.1574 68.3407 78.8874 69.3151 77.4141L72.8607 79.343C71.8862 80.8163 70.3807 82.0852 68.3229 83.1541Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_144"
                        d="M70.1979 82.0148L66.6523 80.0859"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_145"
                        d="M71.7077 80.7492L68.1621 78.8203"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_146"
                    d="M64.8815 84.5052L61.3359 82.5763C62.6337 82.1985 63.7815 81.7474 64.7782 81.2252C66.8359 80.1574 68.3415 78.8874 69.3159 77.4141L72.8615 79.3429C71.887 80.8163 70.3815 82.0852 68.3237 83.1541C67.327 83.6774 66.1793 84.1274 64.8815 84.5052Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_101">
            <g id="Clip path group_29">
              <mask id="mask28_1007_14489" maskUnits="userSpaceOnUse" x="53" y="35" width="5" height="20">
                <g id="clippath-28">
                  <path
                    id="Vector_147"
                    d="M57.5163 54.4523L53.9707 52.5234L54.004 35.0156L57.5496 36.9445L57.5163 54.4523Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask28_1007_14489)">
                <g id="Group_102">
                  <path
                    id="Vector_148"
                    d="M57.5163 54.4523L53.9707 52.5234L54.004 35.0156L57.5496 36.9445L57.5163 54.4523Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_103">
            <g id="Clip path group_30">
              <mask id="mask29_1007_14489" maskUnits="userSpaceOnUse" x="48" y="50" width="10" height="5">
                <g id="clippath-29">
                  <path
                    id="Vector_149"
                    d="M51.7604 52.8039L48.2148 50.875C49.3382 51.4861 50.6682 51.9328 52.2026 52.2161C52.7748 52.3217 53.3637 52.4239 53.9704 52.5217L57.516 54.4506C56.9093 54.3517 56.3193 54.2506 55.7482 54.145C54.2137 53.8628 52.8837 53.415 51.7604 52.8039Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask29_1007_14489)">
                <g id="Group_104">
                  <g id="Group_105">
                    <g id="Group_106">
                      <path
                        id="Vector_150"
                        d="M50.4108 51.9133L46.8652 49.9844C48.2186 51.0666 50.0008 51.811 52.2019 52.2166L55.7475 54.1455C53.5463 53.7399 51.7641 52.9955 50.4108 51.9133Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_151"
                        d="M51.9069 52.882L48.3613 50.9531"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_152"
                        d="M53.6862 53.6242L50.1406 51.6953"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_107">
                      <path
                        id="Vector_153"
                        d="M55.7467 54.1476L52.2012 52.2188C52.7734 52.3243 53.3623 52.4265 53.969 52.5243L57.5145 54.4532C56.9078 54.3543 56.3178 54.2532 55.7467 54.1476Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_154"
                        d="M56.3249 54.2492L52.7793 52.3203"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_155"
                        d="M56.9147 54.3508L53.3691 52.4219"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_156"
                    d="M51.7604 52.8039L48.2148 50.875C49.3382 51.4861 50.6682 51.9328 52.2026 52.2161C52.7748 52.3217 53.3637 52.4239 53.9704 52.5217L57.516 54.4506C56.9093 54.3517 56.3193 54.2506 55.7482 54.145C54.2137 53.8628 52.8837 53.415 51.7604 52.8039Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_108">
            <g id="Clip path group_31">
              <mask id="mask30_1007_14489" maskUnits="userSpaceOnUse" x="44" y="35" width="14" height="18">
                <g id="clippath-30">
                  <path
                    id="Vector_157"
                    d="M48.2163 50.8767C47.7274 50.6112 47.2774 50.3134 46.8674 49.9856C45.5129 48.9034 44.8385 47.3201 44.8418 45.2367C44.8451 43.479 45.3129 41.8467 46.2507 40.3467C47.1896 38.8467 48.6729 37.5334 50.694 36.3978C51.6563 35.8612 52.7607 35.4012 54.0051 35.0156L57.5507 36.9445C56.3063 37.3301 55.2029 37.7901 54.2396 38.3267C52.2185 39.4623 50.7351 40.7756 49.7963 42.2756C48.8585 43.7756 48.3907 45.4078 48.3874 47.1656C48.384 49.249 49.0585 50.8323 50.4129 51.9145C50.8229 52.2423 51.2729 52.5401 51.7618 52.8056C50.6985 52.2267 49.2796 51.4556 48.2163 50.8767Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask30_1007_14489)">
                <g id="Group_109">
                  <g id="Group_110">
                    <g id="Group_111">
                      <path
                        id="Vector_158"
                        d="M57.55 36.9445L54.0045 35.0156C52.76 35.4012 51.6567 35.8612 50.6934 36.3978L54.2389 38.3267C55.2011 37.7901 56.3056 37.3301 57.55 36.9445Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_159"
                        d="M56.3522 37.3508L52.8066 35.4219"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_160"
                        d="M55.2487 37.8195L51.7031 35.8906"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_112">
                      <path
                        id="Vector_161"
                        d="M54.2389 38.3273L50.6933 36.3984C48.6722 37.534 47.1889 38.8473 46.25 40.3473L49.7956 42.2762C50.7344 40.7762 52.2178 39.4629 54.2389 38.3273Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_162"
                        d="M52.3971 39.5226L48.8516 37.5938"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_163"
                        d="M50.9147 40.8351L47.3691 38.9062"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <g id="Group_113">
                      <path
                        id="Vector_164"
                        d="M49.7962 42.2726L46.2507 40.3438C45.3129 41.8437 44.8451 43.476 44.8418 45.2338L48.3874 47.1626C48.3907 45.4049 48.8585 43.7726 49.7962 42.2726Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_165"
                        d="M49.0143 43.8195L45.4688 41.8906"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path id="Vector_166" d="M48.5456 45.4434L45 43.5156" stroke="#E6EDF3" stroke-miterlimit="10" />
                    </g>
                    <g id="Group_114">
                      <path
                        id="Vector_167"
                        d="M48.3874 47.1633L44.8418 45.2344C44.8385 47.3177 45.5129 48.901 46.8674 49.9833L50.4129 51.9122C49.0585 50.8299 48.384 49.2466 48.3874 47.1633Z"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_168"
                        d="M48.61 49.0773L45.0645 47.1484"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                      <path
                        id="Vector_169"
                        d="M49.2858 50.6633L45.7402 48.7344"
                        stroke="#E6EDF3"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <path
                      id="Vector_170"
                      d="M50.4127 51.9133L46.8672 49.9844C48.2205 51.0666 50.0027 51.811 52.2039 52.2166L55.7494 54.1455C53.5483 53.7399 51.7661 52.9955 50.4127 51.9133Z"
                      stroke="#E6EDF3"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <path
                    id="Vector_171"
                    d="M48.2163 50.8767C47.7274 50.6112 47.2774 50.3134 46.8674 49.9856C45.5129 48.9034 44.8385 47.3201 44.8418 45.2367C44.8451 43.479 45.3129 41.8467 46.2507 40.3467C47.1896 38.8467 48.6729 37.5334 50.694 36.3978C51.6563 35.8612 52.7607 35.4012 54.0051 35.0156L57.5507 36.9445C56.3063 37.3301 55.2029 37.7901 54.2396 38.3267C52.2185 39.4623 50.7351 40.7756 49.7963 42.2756C48.8585 43.7756 48.3907 45.4078 48.3874 47.1656C48.384 49.249 49.0585 50.8323 50.4129 51.9145C50.8229 52.2423 51.2729 52.5401 51.7618 52.8056C50.6985 52.2267 49.2796 51.4556 48.2163 50.8767Z"
                    stroke="#E6EDF3"
                    stroke-miterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
