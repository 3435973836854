export const WhatsAppFooter = () => {
  return (
    <svg width="75%" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4844 38.828C18.4195 38.828 15.3546 38.0334 12.7438 36.4443L12.0628 36.1037L5.47897 37.8064L7.18167 31.3361L6.72762 30.6551C1.73302 22.5956 4.11681 11.9253 12.2898 6.93074C20.4628 1.93615 31.0195 4.31993 36.0141 12.4929C41.0087 20.6659 38.6249 31.2226 30.4519 36.2172C27.8411 37.9199 24.6628 38.828 21.4844 38.828ZM30.2249 25.6605L31.4736 26.228C31.7006 26.3416 31.8141 26.4551 31.8141 26.4551V27.2497C31.7006 27.8172 31.5871 28.3848 31.3601 28.8389C31.2465 29.0659 31.0195 29.2929 30.7925 29.5199C30.5655 29.747 30.3384 29.8605 30.1114 30.0875L29.8844 30.3145C29.6573 30.5416 29.4303 30.5416 29.0898 30.7686C28.6357 30.9956 27.9546 31.2226 27.3871 31.2226H26.2519C25.9114 31.1091 25.4573 31.1091 25.1168 30.9956C22.0519 30.201 19.3276 28.7253 16.9438 26.6821C16.7168 26.3416 16.3763 26.1145 16.0357 25.774L15.5817 25.3199C13.7655 23.6172 12.4033 21.6875 11.3817 19.5307L11.2682 19.1902C10.9276 18.3956 10.7006 17.4875 10.7006 16.5794C10.7006 15.3307 11.1546 14.0821 11.9492 13.0605C12.1763 12.8334 12.2898 12.6064 12.5168 12.3794C12.7438 12.2659 12.9709 12.0389 13.1979 11.9253L13.3114 11.8118C13.5384 11.8118 13.7655 11.6983 13.9925 11.6983H14.9006H15.2411C15.5817 11.8118 15.8087 11.9253 16.0357 12.2659C16.3763 12.947 17.7384 16.0118 17.8519 16.5794C17.9655 16.9199 17.8519 17.374 17.6249 17.7145C17.3979 17.9416 17.2844 18.2821 17.0573 18.5091C16.7168 18.8497 16.2628 19.4172 16.2628 19.4172C16.1492 19.5307 16.1492 19.7578 16.1492 19.9848C16.2628 20.2118 16.2628 20.3253 16.3763 20.4389L16.4898 20.6659C17.0574 21.6875 17.8519 22.5956 18.6465 23.3902C18.8736 23.6172 19.2141 23.8443 19.4411 24.0713C20.3492 24.9794 21.4844 25.6605 22.733 26.228L23.3006 26.4551C23.4141 26.5686 23.6411 26.6821 23.7546 26.6821H23.8682C24.0952 26.6821 24.3222 26.5686 24.4357 26.3416C26.0249 24.5253 26.1384 24.4118 26.1384 24.4118C26.3655 24.2983 26.5925 24.1848 26.933 24.1848C27.0465 24.1848 27.1601 24.2983 27.2736 24.2983C28.4087 24.8659 30.2249 25.6605 30.2249 25.6605Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4844 0.6875C26.933 0.6875 32.2682 2.84426 36.1276 6.81723C39.9871 10.6767 42.1438 15.8983 42.1438 21.4605C42.1438 32.9253 32.8357 42.347 21.3709 42.347C17.9655 42.347 14.5601 41.5524 11.4952 39.8497L0.484375 42.6875L3.43573 31.9037C1.73302 28.7253 0.711402 25.2064 0.711402 21.574C0.597889 10.1091 9.906 0.6875 21.4844 0.6875ZM12.0628 36.1037L12.7438 36.4443C15.3546 38.0334 18.4195 38.828 21.4844 38.828C24.6628 38.828 27.8411 37.9199 30.4519 36.2172C38.6249 31.2226 41.0087 20.6659 36.0141 12.4929C31.0195 4.31993 20.4628 1.93615 12.2898 6.93074C4.11681 11.9253 1.73302 22.5956 6.72762 30.6551L7.18167 31.3361L5.47897 37.8064L12.0628 36.1037Z"
        fill="white"
      />
    </svg>
  )
}
