export const ThreeDotsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.475 14.075C13.3587 14.075 14.075 13.3587 14.075 12.475C14.075 11.5913 13.3587 10.875 12.475 10.875C11.5913 10.875 10.875 11.5913 10.875 12.475C10.875 13.3587 11.5913 14.075 12.475 14.075Z"
        fill="#094E91"
      />
      <path
        d="M12.475 8.075C13.3587 8.075 14.075 7.35866 14.075 6.475C14.075 5.59134 13.3587 4.875 12.475 4.875C11.5913 4.875 10.875 5.59134 10.875 6.475C10.875 7.35866 11.5913 8.075 12.475 8.075Z"
        fill="#094E91"
      />
      <path
        d="M12.475 20.075C13.3587 20.075 14.075 19.3587 14.075 18.475C14.075 17.5913 13.3587 16.875 12.475 16.875C11.5913 16.875 10.875 17.5913 10.875 18.475C10.875 19.3587 11.5913 20.075 12.475 20.075Z"
        fill="#094E91"
      />
    </svg>
  )
}
