const Remove = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M10.4387 14.75C10.5314 14.8437 10.6416 14.9181 10.763 14.9689C10.8845 15.0197 11.0147 15.0458 11.1463 15.0458C11.2778 15.0458 11.4081 15.0197 11.5295 14.9689C11.651 14.9181 11.7612 14.8437 11.8539 14.75L15.9199 10.67C16.1075 10.4817 16.213 10.2263 16.213 9.96C16.213 9.6937 16.1075 9.4383 15.9199 9.25C15.7322 9.0617 15.4777 8.95591 15.2123 8.95591C14.9469 8.95591 14.6924 9.0617 14.5047 9.25L11.1463 12.63L9.94043 11.41C9.75277 11.2217 9.49825 11.1159 9.23286 11.1159C8.96747 11.1159 8.71295 11.2217 8.52529 11.41C8.33764 11.5983 8.23221 11.8537 8.23221 12.12C8.23221 12.3863 8.33764 12.6417 8.52529 12.83L10.4387 14.75ZM21.1918 2H3.25341C2.9891 2 2.73562 2.10536 2.54873 2.29289C2.36183 2.48043 2.25684 2.73478 2.25684 3V21C2.25684 21.2652 2.36183 21.5196 2.54873 21.7071C2.73562 21.8946 2.9891 22 3.25341 22H21.1918C21.4561 22 21.7096 21.8946 21.8965 21.7071C22.0833 21.5196 22.1883 21.2652 22.1883 21V3C22.1883 2.73478 22.0833 2.48043 21.8965 2.29289C21.7096 2.10536 21.4561 2 21.1918 2ZM20.1952 20H4.24999V4H20.1952V20Z"
        fill="#094E91"
      />
    </svg>
  )
}

export default Remove
