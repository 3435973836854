export const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
      <path
        d="M55.4167 5.83331H14.5833C12.2627 5.83331 10.0371 6.75519 8.39616 8.39613C6.75522 10.0371 5.83334 12.2627 5.83334 14.5833V17.9958C5.83293 19.2002 6.08116 20.3918 6.56251 21.4958V21.6708C6.97458 22.607 7.55824 23.4577 8.28334 24.1791L26.25 42.0291V61.25C26.249 61.7457 26.3744 62.2334 26.6142 62.6672C26.8541 63.101 27.2005 63.4664 27.6208 63.7291C28.085 64.0168 28.6206 64.1684 29.1667 64.1666C29.6233 64.1639 30.0728 64.054 30.4792 63.8458L42.1458 58.0125C42.6268 57.7701 43.0313 57.3993 43.3145 56.9411C43.5976 56.4829 43.7484 55.9553 43.75 55.4166V42.0291L61.6 24.1791C62.3251 23.4577 62.9088 22.607 63.3208 21.6708V21.4958C63.8424 20.4004 64.1304 19.2085 64.1667 17.9958V14.5833C64.1667 12.2627 63.2448 10.0371 61.6039 8.39613C59.9629 6.75519 57.7373 5.83331 55.4167 5.83331ZM38.7625 38.7625C38.4922 39.035 38.2783 39.3582 38.1332 39.7136C37.988 40.0689 37.9145 40.4495 37.9167 40.8333V53.6083L32.0833 56.525V40.8333C32.0856 40.4495 32.012 40.0689 31.8668 39.7136C31.7217 39.3582 31.5078 39.035 31.2375 38.7625L15.7792 23.3333H54.2208L38.7625 38.7625ZM58.3333 17.5H11.6667V14.5833C11.6667 13.8098 11.974 13.0679 12.5209 12.5209C13.0679 11.9739 13.8098 11.6666 14.5833 11.6666H55.4167C56.1902 11.6666 56.9321 11.9739 57.4791 12.5209C58.0261 13.0679 58.3333 13.8098 58.3333 14.5833V17.5Z"
        fill="#094E91"
      />
    </svg>
  )
}
