export const LogoFooter = () => {
  return (
    <svg width="169" height="53" viewBox="0 0 169 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.395 52.1122V41.8174H18.7277V52.1122H26.395Z" fill="#E6EDF3" />
      <path
        d="M26.4391 52.2177H18.6216V41.7717L21.2397 39.1353H29.0572V49.5813L26.4391 52.2177ZM18.8337 52.0029H26.3509L28.8439 49.4925V39.3501H21.3267L18.8337 41.8605V52.0029Z"
        fill="#E6EDF3"
      />
      <path d="M26.3949 39.2428H18.7275L26.3949 31.522V39.2428Z" fill="#E6EDF3" />
      <path
        d="M26.4391 39.3498H18.4702L29.0572 28.689V36.7134L26.4391 39.3498ZM18.985 39.135H26.3509L28.8439 36.6246V29.2074L18.9838 39.1362L18.985 39.135Z"
        fill="#E6EDF3"
      />
      <path d="M18.7275 28.9482H26.3949L18.7275 36.669V28.9482Z" fill="#E6EDF3" />
      <path
        d="M18.6216 36.9284V28.904L21.2397 26.2676H29.2086L18.6216 36.9284ZM18.8349 28.9928V36.41L28.695 26.4812H21.3291L18.8361 28.9916L18.8349 28.9928Z"
        fill="#E6EDF3"
      />
      <path
        d="M26.4391 26.4823H18.6216V16.0363L21.2397 13.3999H29.0572V23.8459L26.4391 26.4823ZM18.8337 26.2675H26.3509L28.8439 23.7571V13.6147H21.3267L18.8337 16.1251V26.2675Z"
        fill="#E6EDF3"
      />
      <path d="M26.395 26.3754V16.0806H18.7277V26.3754H26.395Z" fill="#E6EDF3" />
      <path d="M46.766 0.563326L43.8555 3.49414L44.0063 3.64603L46.9168 0.715213L46.766 0.563326Z" fill="#E6EDF3" />
      <path d="M26.395 10.9327L34.0624 3.21191H44.2859V10.9327H26.395Z" fill="#E6EDF3" />
      <path
        d="M44.3298 11.0406H26.1387L36.5743 0.532227H46.948V8.40423L44.3298 11.0406ZM26.6523 10.8258H44.2417L46.7347 8.31543V0.745826H36.6625L26.6523 10.8258Z"
        fill="#E6EDF3"
      />
      <path d="M23.8384 3.21191H31.5057L23.8384 10.9327V3.21191Z" fill="#E6EDF3" />
      <path
        d="M23.7324 11.192V3.16765L26.3506 0.53125H34.3194L23.7324 11.192ZM23.9457 3.25645V10.6737L33.8058 0.74485H26.4399L23.9469 3.25525L23.9457 3.25645Z"
        fill="#E6EDF3"
      />
      <path d="M21.2836 10.9327H13.6162L21.2836 3.21191V10.9327Z" fill="#E6EDF3" />
      <path
        d="M21.3277 11.0407H13.3589L23.9459 0.379883V8.40428L21.3277 11.0407ZM13.8737 10.8259H21.2395L23.7326 8.31548V0.898283L13.8725 10.8271L13.8737 10.8259Z"
        fill="#E6EDF3"
      />
      <path d="M18.7273 3.21191L11.06 10.9327H0.836426V3.21191H18.7273Z" fill="#E6EDF3" />
      <path
        d="M11.1042 11.0408H0.730469V3.16765L3.34862 0.53125H21.5398L11.1042 11.0397V11.0408ZM0.943782 10.8261H11.016L21.0262 0.74605H3.4368L0.943782 3.25645V10.8261Z"
        fill="#E6EDF3"
      />
      <path d="M28.8751 39.1673L26 42.0625L26.1508 42.2144L29.026 39.3192L28.8751 39.1673Z" fill="#E6EDF3" />
      <path d="M28.8746 13.431L25.9995 16.3262L26.1503 16.4781L29.0255 13.5829L28.8746 13.431Z" fill="#E6EDF3" />
      <path
        d="M39.4427 52.2334C37.7743 52.2334 36.2919 51.883 35.0024 51.1786C33.7107 50.4742 32.6918 49.513 31.9422 48.2902C31.195 47.0686 30.8208 45.6742 30.8208 44.1106C30.8208 42.547 31.195 41.1226 31.9422 39.8998C32.6918 38.6782 33.7107 37.7206 35.0024 37.0258C36.2919 36.331 37.7731 35.9854 39.4427 35.9854C41.1123 35.9854 42.5697 36.331 43.871 37.0258C45.1724 37.7206 46.1912 38.6734 46.9289 39.8854C47.6689 41.0974 48.036 42.5062 48.036 44.1106C48.036 45.715 47.6689 47.0686 46.9289 48.2902C46.1912 49.5118 45.1724 50.4742 43.871 51.1786C42.5697 51.883 41.0956 52.2334 39.4427 52.2334ZM39.4427 48.4786C40.2018 48.4786 40.8811 48.3046 41.4841 47.9506C42.0835 47.6002 42.5602 47.0962 42.9105 46.4398C43.2585 45.7858 43.4349 45.0082 43.4349 44.1106C43.4349 43.213 43.2597 42.409 42.9105 41.7622C42.5602 41.1178 42.0835 40.6186 41.4841 40.2682C40.8823 39.9154 40.2018 39.7402 39.4427 39.7402C38.6836 39.7402 38.0067 39.9142 37.4037 40.2682C36.8019 40.6186 36.3228 41.1178 35.963 41.7622C35.6031 42.409 35.4243 43.1926 35.4243 44.1106C35.4243 45.0286 35.6019 45.7858 35.963 46.4398C36.3228 47.0962 36.8019 47.6002 37.4037 47.9506C38.0055 48.3034 38.686 48.4786 39.4427 48.4786Z"
        fill="#E6EDF3"
      />
      <path
        d="M54.2101 51.9992L48.5591 36.2192H52.842L57.5313 49.7984H55.4923L60.3854 36.2192H64.2298L68.9775 49.7984H66.9386L71.7744 36.2192H75.7952L70.1132 51.9992H65.7159L61.5509 40.3556H62.8904L58.5788 51.9992H54.2101Z"
        fill="#E6EDF3"
      />
      <path
        d="M87.4464 35.9839C88.6881 35.9839 89.8 36.2323 90.7819 36.7315C91.7615 37.2307 92.5337 37.9915 93.0974 39.0211C93.6587 40.0471 93.9411 41.3623 93.9411 42.9643V51.9991H89.3972V43.6687C89.3972 42.3991 89.1195 41.4607 88.5678 40.8547C88.016 40.2487 87.2331 39.9451 86.2237 39.9451C85.5051 39.9451 84.858 40.0951 84.2872 40.3987C83.714 40.7023 83.2683 41.1679 82.9453 41.7931C82.6248 42.4183 82.4663 43.2211 82.4663 44.1967V52.0003H77.9224V36.2203H82.2625V40.5907L81.4474 39.2707C82.0087 38.2159 82.8154 37.4035 83.8629 36.8359C84.9128 36.2707 86.1081 35.9863 87.4476 35.9863L87.4464 35.9839Z"
        fill="#E6EDF3"
      />
      <path
        d="M103.321 52.2334C101.981 52.2334 100.694 52.0738 99.4622 51.751C98.2276 51.4258 97.2528 51.0202 96.5342 50.5342L98.0488 47.2474C98.7674 47.6986 99.6159 48.0634 100.597 48.3478C101.579 48.6322 102.543 48.7726 103.496 48.7726C104.544 48.7726 105.286 48.6466 105.725 48.3934C106.161 48.1378 106.379 47.7874 106.379 47.3362C106.379 46.9666 106.208 46.687 105.869 46.501C105.53 46.315 105.077 46.1734 104.515 46.0762C103.951 45.9778 103.334 45.8806 102.663 45.7822C101.995 45.6838 101.319 45.553 100.638 45.3862C99.9603 45.2218 99.337 44.971 98.7758 44.6386C98.2121 44.3074 97.7616 43.8562 97.422 43.2874C97.0812 42.7222 96.9119 41.989 96.9119 41.089C96.9119 40.0918 97.1968 39.211 97.77 38.449C98.3432 37.6846 99.1738 37.0858 100.262 36.6442C101.347 36.205 102.649 35.9854 104.163 35.9854C105.232 35.9854 106.32 36.1018 107.425 36.3358C108.532 36.5722 109.455 36.913 110.193 37.3618L108.679 40.6174C107.92 40.1686 107.159 39.8602 106.391 39.6934C105.626 39.529 104.881 39.445 104.162 39.445C103.153 39.445 102.415 39.5806 101.948 39.8554C101.483 40.1302 101.249 40.4806 101.249 40.9102C101.249 41.3014 101.419 41.5954 101.759 41.791C102.099 41.9866 102.55 42.1414 103.115 42.259C103.676 42.3778 104.296 42.481 104.964 42.5674C105.635 42.6562 106.308 42.7894 106.989 42.9634C107.669 43.1398 108.285 43.3882 108.838 43.7134C109.393 44.0362 109.843 44.4754 110.195 45.0334C110.543 45.589 110.719 46.3174 110.719 47.2174C110.719 48.1966 110.427 49.0606 109.844 49.8142C109.264 50.5666 108.422 51.1582 107.325 51.5878C106.23 52.0174 104.893 52.2322 103.321 52.2322V52.2334Z"
        fill="#E6EDF3"
      />
      <path
        d="M121.294 52.2334C119.507 52.2334 117.937 51.883 116.589 51.1786C115.24 50.4742 114.195 49.513 113.458 48.2902C112.72 47.0686 112.351 45.6742 112.351 44.1106C112.351 42.547 112.715 41.1226 113.443 39.8998C114.171 38.6782 115.167 37.7206 116.43 37.0258C117.691 36.331 119.118 35.9854 120.71 35.9854C122.302 35.9854 123.629 36.313 124.861 36.967C126.093 37.6234 127.07 38.5618 127.789 39.7834C128.508 41.005 128.867 42.4666 128.867 44.1682C128.867 44.3446 128.856 44.545 128.836 44.7694C128.817 44.9938 128.798 45.2038 128.779 45.3994H116.05V42.7306H126.39L124.643 43.5226C124.643 42.7018 124.477 41.9878 124.147 41.3818C123.816 40.7758 123.361 40.3006 122.777 39.9586C122.196 39.6178 121.516 39.445 120.738 39.445C119.96 39.445 119.278 39.6166 118.684 39.9586C118.092 40.2994 117.63 40.7794 117.3 41.395C116.971 42.0106 116.804 42.739 116.804 43.579V44.2834C116.804 45.145 116.993 45.9022 117.372 46.5586C117.751 47.2126 118.285 47.7118 118.975 48.0526C119.663 48.3958 120.476 48.5662 121.407 48.5662C122.242 48.5662 122.975 48.4402 123.607 48.187C124.238 47.9314 124.813 47.5498 125.34 47.041L127.758 49.681C127.037 50.5018 126.134 51.1342 125.048 51.5734C123.96 52.0126 122.709 52.2322 121.291 52.2322L121.294 52.2334Z"
        fill="#E6EDF3"
      />
      <path
        d="M141.422 35.9839C142.664 35.9839 143.776 36.2323 144.757 36.7315C145.737 37.2307 146.509 37.9915 147.073 39.0211C147.634 40.0471 147.917 41.3623 147.917 42.9643V51.9991H143.373V43.6687C143.373 42.3991 143.095 41.4607 142.543 40.8547C141.992 40.2487 141.209 39.9451 140.199 39.9451C139.481 39.9451 138.834 40.0951 138.263 40.3987C137.69 40.7023 137.244 41.1679 136.921 41.7931C136.6 42.4183 136.442 43.2211 136.442 44.1967V52.0003H131.898V36.2203H136.238V40.5907L135.423 39.2707C135.984 38.2159 136.791 37.4035 137.839 36.8359C138.888 36.2707 140.084 35.9863 141.423 35.9863L141.422 35.9839Z"
        fill="#E6EDF3"
      />
      <path
        d="M158.868 52.2333C157.394 52.2333 156.062 51.8973 154.879 51.2217C153.693 50.5485 152.757 49.6053 152.067 48.3933C151.377 47.1813 151.034 45.7533 151.034 44.1105C151.034 42.4677 151.377 41.0097 152.067 39.7977C152.757 38.5857 153.694 37.6473 154.879 36.9837C156.062 36.3177 157.393 35.9865 158.868 35.9865C160.189 35.9865 161.346 36.2805 162.336 36.8649C163.324 37.4517 164.093 38.3421 164.635 39.5361C165.181 40.7277 165.45 42.2529 165.45 44.1117C165.45 45.9705 165.189 47.4705 164.666 48.6705C164.139 49.8741 163.388 50.7681 162.406 51.3561C161.425 51.9405 160.247 52.2345 158.867 52.2345L158.868 52.2333ZM159.655 48.4785C160.393 48.4785 161.063 48.3045 161.665 47.9505C162.267 47.6001 162.746 47.0961 163.106 46.4397C163.466 45.7857 163.645 45.0081 163.645 44.1105C163.645 43.2129 163.467 42.4089 163.106 41.7621C162.746 41.1177 162.267 40.6185 161.665 40.2681C161.063 39.9153 160.393 39.7401 159.655 39.7401C158.917 39.7401 158.219 39.9141 157.616 40.2681C157.014 40.6185 156.533 41.1177 156.175 41.7621C155.815 42.4089 155.634 43.1925 155.634 44.1105C155.634 45.0285 155.814 45.7857 156.175 46.4397C156.533 47.0961 157.014 47.6001 157.616 47.9505C158.218 48.3033 158.898 48.4785 159.655 48.4785ZM163.763 51.9993V48.7725L163.851 44.0793L163.559 39.4149V30.2349H168.103V51.9993H163.763Z"
        fill="#E6EDF3"
      />
    </svg>
  )
}
