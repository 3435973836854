const NotificationsBell = () => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4407 34.1254C18.4363 31.0134 19.0476 27.9312 20.2395 25.0565C21.4314 22.1818 23.1803 19.5713 25.3853 17.3753C27.5904 15.1794 30.2082 13.4414 33.0878 12.2615C35.9675 11.0816 39.0521 10.483 42.1641 10.5004C55.1578 10.5988 65.5594 21.3941 65.5594 34.4207V36.7504C65.5594 48.4972 68.0203 55.3222 70.186 59.0629C70.416 59.4612 70.5372 59.9131 70.5377 60.3731C70.5381 60.8331 70.4176 61.2851 70.1883 61.6839C69.959 62.0826 69.6289 62.4142 69.2312 62.6452C68.8334 62.8763 68.3819 62.9987 67.9219 63.0004H16.0782C15.6182 62.9987 15.1667 62.8763 14.7689 62.6452C14.3711 62.4142 14.0411 62.0826 13.8118 61.6839C13.5825 61.2851 13.462 60.8331 13.4624 60.3731C13.4628 59.9131 13.5841 59.4612 13.8141 59.0629C15.9797 55.3222 18.4407 48.4972 18.4407 36.7504V34.1254Z"
        stroke="#BFC1C8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5 63V65.625C31.5 68.4098 32.6062 71.0805 34.5754 73.0496C36.5445 75.0188 39.2152 76.125 42 76.125C44.7848 76.125 47.4555 75.0188 49.4246 73.0496C51.3938 71.0805 52.5 68.4098 52.5 65.625V63"
        stroke="#BFC1C8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NotificationsBell
