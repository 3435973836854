export const VKFooter = () => {
  return (
    <svg width="75%" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.8987 2.8013C47.2325 1.68879 46.8987 0.871094 45.3106 0.871094H40.0594C38.724 0.871094 38.1089 1.57738 37.7751 2.35644C37.7751 2.35644 35.1044 8.86534 31.3213 13.0933C30.0974 14.3172 29.5411 14.7062 28.8735 14.7062C28.5397 14.7062 28.0566 14.3172 28.0566 13.2047V2.8013C28.0566 1.46598 27.6691 0.871094 26.5562 0.871094H18.3043C17.4701 0.871094 16.9682 1.49074 16.9682 2.07813C16.9682 3.34405 18.8594 3.63586 19.0544 7.1962V14.9294C19.0544 16.6248 18.7484 16.9323 18.0807 16.9323C16.3006 16.9323 11.9705 10.3938 9.40195 2.9127C8.89858 1.45848 8.39371 0.871094 7.05165 0.871094H1.80042C0.30007 0.871094 0 1.57738 0 2.35644C0 3.74727 1.78054 10.6459 8.28944 19.7695C12.6292 25.9997 18.7424 29.3778 24.3057 29.3778C27.644 29.3778 28.0566 28.6276 28.0566 27.3354V22.6262C28.0566 21.1258 28.3728 20.8265 29.4294 20.8265C30.2088 20.8265 31.5437 21.2158 34.6596 24.2199C38.2199 27.7799 38.8066 29.3778 40.8095 29.3778H46.0608C47.5611 29.3778 48.3113 28.6276 47.8784 27.1471C47.4051 25.6715 45.7052 23.5313 43.4494 20.9934C42.2255 19.5467 40.3894 17.989 39.8335 17.2103C39.0545 16.2088 39.2769 15.7639 39.8335 14.8742C39.8335 14.8742 46.231 5.86164 46.8987 2.80167V2.8013Z"
        fill="white"
      />
    </svg>
  )
}
