import avatarOne from '../../../assets/img/avatarReviewsCard/avatarOne.png'
import avatarThree from '../../../assets/img/avatarReviewsCard/avatarThree.png'
import avatarTwo from '../../../assets/img/avatarReviewsCard/avatarTwo.png'

import { TMockReviewsSection } from './type'

export const mockReviewsSection: TMockReviewsSection[] = [
  {
    avatar: avatarOne,
    name: 'Анна Парамонова',
    date: '25.06.2023',
    text: 'Сотрудничество с агентством мессенджер-маркетинга этого сервиса принесло нашей компании заметные результаты. Мы начали использовать их сервис с 2018 года и с тех пор видим значительный рост в конверсии и эффективности наших бизнес-процессов. Чат-боты, предоставленные этим сервисом, позволили нам автоматизировать продажи и достичь поставленных KPI, включая увеличение количества заявок и продаж. Особенно порадовал быстрый запуск решений - не нужно тратить время на разработку и тестирование, мы сразу получили готовые шаблоны, проверенные на практике и принесшие результаты в самых разных сферах бизнеса. '
  },
  {
    avatar: avatarTwo,
    name: 'Алексей Нилов',
    date: '02.07.2023',
    text: 'Этот сервис дал нам возможность реализовать наши бизнес-идеи без лишних затрат времени и ресурсов. Мы оценили их подход "под ключ" на основе KPI, что позволило нам сосредоточиться на конверсиях, заявках и продажах. Благодаря готовым шаблонам чат-ботов, предоставленным агентством, мы сразу же приступили к работе, минуя долгие этапы разработки и тестирования. На сайте этого сервиса мы нашли только проверенные решения, гарантированно работающие и приносящие результаты. Это значительно упростило наш выбор и помогло нам успешно решить задачи нашего бизнеса'
  },
  {
    avatar: avatarThree,
    name: 'Инга Муратова',
    date: '13.02.2023',
    text: 'Удобный интерфейс, проста в работе, хорошо усваивается бухгалтерами. Можно вести несколько предприятий в одной программе. Удобный интерфейс, проста в работе, хорошо усваивается бухгалтерами. Можно вести несколько предприятий в одной программе.  Удобный интерфейс, проста в работе, хорошо усваивается бухгалтерами. Можно вести несколько предприятий в одной программе. Удобный интерфейс, проста в работе, хорошо усваивается бухгалтерами. Можно вести несколько предприятий в одной программе.'
  }
]
