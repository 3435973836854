export const Telegram = ({ width = '24', height = '24', fill = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0ZM16.7256 5.73C17.2872 5.5584 17.6148 5.574 17.8176 5.7456C18.0516 5.9496 18.0216 6.3072 17.9736 6.6192L17.9472 6.7872L17.8956 7.1184L17.742 8.0088C17.5872 8.9052 17.3832 10.0944 17.1468 11.2992C16.7425 13.3174 16.3109 15.3299 15.852 17.3364C15.8321 17.3977 15.8165 17.4602 15.8052 17.5236C15.7272 17.9448 15.4932 18.2568 15.1656 18.366C15.0752 18.3971 14.9804 18.4133 14.8848 18.414C14.604 18.414 14.292 18.2724 13.9968 18.0228L13.98 18.0072C13.8552 17.8824 12.5136 16.8072 11.2812 15.8388C11.0004 16.1352 10.002 17.1804 9.4248 17.6484C9.32558 17.7236 9.20596 17.7671 9.0816 17.7732C9.03234 17.7759 8.98309 17.7676 8.93743 17.749C8.89176 17.7303 8.85083 17.7017 8.8176 17.6652C8.64268 17.5489 8.51932 17.3697 8.4732 17.1648C8.4372 17.022 7.8912 15.1716 7.566 14.0724L7.35 13.3428C6.8976 13.2024 4.7604 12.4848 4.278 12.2352C3.8244 12.0324 3.5904 11.7828 3.606 11.5032C3.6372 11.0652 4.278 10.7844 4.5732 10.6908C4.8384 10.566 7.0692 9.5676 9.8148 8.4288C12.8724 7.1652 16.3356 5.8548 16.7268 5.73H16.7256ZM15.12 8.3664L7.68 13.296L8.1396 14.8524C8.4372 15.8616 8.7648 16.9752 8.802 17.0868V17.1024L8.8416 16.6824C8.9124 15.9264 9.0192 14.7816 9.0828 14.0604V14.0292C9.0984 14.0136 9.0984 14.0136 9.0984 13.998C9.114 13.998 9.114 13.9824 9.114 13.9824L9.1296 13.9668V13.9512C10.1208 13.056 11.5488 11.7456 12.7668 10.6296L13.2948 10.1448L15.1824 8.382C15.2016 8.364 15.2136 8.34 15.2244 8.322C15.2298 8.31001 15.2367 8.29873 15.2448 8.2884C15.2364 8.298 15.2328 8.3016 15.228 8.3028L15.2136 8.304C15.2064 8.304 15.2016 8.3088 15.198 8.3124C15.1944 8.316 15.1908 8.3196 15.1824 8.3196C15.1728 8.32854 15.1623 8.33658 15.1512 8.3436C15.1392 8.3508 15.1272 8.358 15.12 8.3664Z"
        fill={fill}
      />
    </svg>
  )
}
