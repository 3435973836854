import { useWindowSize } from '../../hooks/useWindowsSize'

const PasswordOpenIcon = () => {
  const { width: widthWindow = 0 } = useWindowSize()

  return (
    <svg
      width={widthWindow > 320 ? '20' : '14'}
      height={widthWindow > 320 ? '20' : '14'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 12C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10C12 9.46957 11.7893 8.96086 11.4142 8.58579C11.0391 8.21071 10.5304 8 10 8C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10C8 10.5304 8.21071 11.0391 8.58579 11.4142C8.96086 11.7893 9.46957 12 10 12Z"
        fill="#BFC1C8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.457031 10C1.73103 5.943 5.52103 3 9.99903 3C14.477 3 18.267 5.943 19.541 10C18.267 14.057 14.477 17 9.99903 17C5.52103 17 1.73103 14.057 0.457031 10ZM13.999 10C13.999 11.0609 13.5776 12.0783 12.8275 12.8284C12.0773 13.5786 11.0599 14 9.99903 14C8.93816 14 7.92075 13.5786 7.1706 12.8284C6.42046 12.0783 5.99903 11.0609 5.99903 10C5.99903 8.93913 6.42046 7.92172 7.1706 7.17157C7.92075 6.42143 8.93816 6 9.99903 6C11.0599 6 12.0773 6.42143 12.8275 7.17157C13.5776 7.92172 13.999 8.93913 13.999 10Z"
        fill="#BFC1C8"
      />
    </svg>
  )
}

export default PasswordOpenIcon
