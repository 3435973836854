import { useWindowSize } from '../../hooks/useWindowsSize'

const PasswordCloseIcon = () => {
  const { width: widthWindow = 0 } = useWindowSize()

  return (
    <svg
      width={widthWindow > 480 ? '20' : '14'}
      height={widthWindow > 480 ? '20' : '14'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70643 2.29304C3.51783 2.11088 3.26523 2.01009 3.00303 2.01237C2.74083 2.01465 2.49002 2.11981 2.30461 2.30522C2.1192 2.49063 2.01403 2.74144 2.01176 3.00364C2.00948 3.26584 2.11027 3.51844 2.29243 3.70704L16.2924 17.707C16.481 17.8892 16.7336 17.99 16.9958 17.9877C17.258 17.9854 17.5088 17.8803 17.6942 17.6949C17.8797 17.5095 17.9848 17.2586 17.9871 16.9964C17.9894 16.7342 17.8886 16.4816 17.7064 16.293L16.2334 14.82C17.791 13.5782 18.9427 11.9 19.5414 10C18.2674 5.94304 14.4774 3.00004 9.99943 3.00004C8.43186 2.99792 6.88593 3.3659 5.48743 4.07404L3.70643 2.29304ZM7.96743 6.55304L9.48143 8.06804C9.82052 7.97799 10.1773 7.97859 10.5161 8.06977C10.8549 8.16095 11.1638 8.33952 11.4119 8.58761C11.6599 8.83569 11.8385 9.14458 11.9297 9.48337C12.0209 9.82216 12.0215 10.179 11.9314 10.518L13.4454 12.032C13.8964 11.2681 14.0806 10.3758 13.9691 9.49572C13.8575 8.6156 13.4566 7.79754 12.8293 7.17022C12.2019 6.5429 11.3839 6.14193 10.5038 6.03039C9.62364 5.91885 8.73141 6.10206 7.96743 6.55304Z"
        fill="#BFC1C8"
      />
      <path
        d="M12.453 16.697L9.74903 13.992C8.77672 13.9311 7.86006 13.5174 7.17109 12.8286C6.48212 12.1398 6.06821 11.2233 6.00703 10.251L2.33403 6.578C1.48924 7.58402 0.85138 8.74692 0.457031 10C1.73103 14.057 5.52203 17 9.99903 17C10.846 17 11.668 16.895 12.453 16.697Z"
        fill="#BFC1C8"
      />
    </svg>
  )
}

export default PasswordCloseIcon
