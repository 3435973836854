import { useWindowSize } from '../../hooks/useWindowsSize'

const Vk = () => {
  const { width: widthWindow = 0 } = useWindowSize()

  return (
    <svg
      width={widthWindow > 1024 ? '22.5' : widthWindow > 480 ? '21' : '17'}
      height={widthWindow > 1024 ? '14.5' : widthWindow > 480 ? '13' : '10.5'}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.025 15.3043C5.18162 15.3043 0.70789 9.82051 0.521484 0.695557H4.45033C4.57938 7.39304 7.47583 10.23 9.77005 10.8149V0.695557H13.4695V6.47177C15.735 6.22317 18.1153 3.59097 18.9183 0.695557H22.6177C22.3151 2.1972 21.7119 3.61902 20.8459 4.87208C19.9799 6.12514 18.8697 7.18248 17.5847 7.97797C19.0191 8.70483 20.286 9.73364 21.3018 10.9965C22.3177 12.2594 23.0594 13.7276 23.478 15.3043H19.4058C19.03 13.9348 18.2662 12.7089 17.2102 11.7802C16.1542 10.8516 14.853 10.2614 13.4695 10.0837V15.3043H13.025Z"
        fill="#032D60"
      />
    </svg>
  )
}

export default Vk
