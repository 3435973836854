const ArrowLineLeft = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 12H6.75" stroke="#032D60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.5 5.25L6.75 12L13.5 18.75"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.75 3.75V20.25" stroke="#032D60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowLineLeft
