export const ZoomOut = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7075 19.2914L16.9975 15.6114C18.4376 13.8158 19.135 11.5367 18.9463 9.24267C18.7576 6.94867 17.6971 4.81415 15.983 3.27801C14.2688 1.74188 12.0313 0.920879 9.73039 0.98384C7.4295 1.0468 5.24018 1.98893 3.6126 3.61651C1.98502 5.24409 1.04289 7.43341 0.979933 9.73429C0.916973 12.0352 1.73797 14.2727 3.27411 15.9869C4.81025 17.701 6.94476 18.7615 9.23876 18.9502C11.5328 19.1389 13.8119 18.4415 15.6075 17.0014L19.2875 20.6814C19.3804 20.7751 19.491 20.8495 19.6129 20.9003C19.7347 20.951 19.8655 20.9772 19.9975 20.9772C20.1295 20.9772 20.2602 20.951 20.382 20.9003C20.5039 20.8495 20.6145 20.7751 20.7075 20.6814C20.8877 20.4949 20.9885 20.2457 20.9885 19.9864C20.9885 19.727 20.8877 19.4778 20.7075 19.2914ZM9.99746 17.0014C8.61299 17.0014 7.25962 16.5908 6.10847 15.8217C4.95733 15.0525 4.06012 13.9592 3.53031 12.6802C3.00049 11.4011 2.86187 9.9936 3.13197 8.63574C3.40206 7.27787 4.06875 6.03059 5.04772 5.05162C6.02668 4.07265 7.27396 3.40597 8.63183 3.13587C9.9897 2.86578 11.3972 3.0044 12.6762 3.53421C13.9553 4.06403 15.0486 4.96123 15.8178 6.11238C16.5869 7.26352 16.9975 8.6169 16.9975 10.0014C16.9975 11.8579 16.26 13.6384 14.9472 14.9511C13.6345 16.2639 11.854 17.0014 9.99746 17.0014ZM13.9975 9.00137H5.99746C5.73225 9.00137 5.47789 9.10673 5.29036 9.29426C5.10282 9.4818 4.99746 9.73615 4.99746 10.0014C4.99746 10.2666 5.10282 10.5209 5.29036 10.7085C5.47789 10.896 5.73225 11.0014 5.99746 11.0014H13.9975C14.2627 11.0014 14.517 10.896 14.7046 10.7085C14.8921 10.5209 14.9975 10.2666 14.9975 10.0014C14.9975 9.73615 14.8921 9.4818 14.7046 9.29426C14.517 9.10673 14.2627 9.00137 13.9975 9.00137Z"
        fill="#032D60"
      />
    </svg>
  )
}
