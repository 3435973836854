export const Archive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M20.2204 2.33003C20.1261 2.22518 20.0108 2.14156 19.8819 2.08468C19.7531 2.02781 19.6137 1.99895 19.473 2.00003H5.52092C5.38018 1.99895 5.24081 2.02781 5.11198 2.08468C4.98315 2.14156 4.86779 2.22518 4.77349 2.33003C4.67945 2.43503 4.60862 2.55882 4.56568 2.69326C4.52274 2.8277 4.50865 2.96974 4.52435 3.11003L6.31818 19.33C6.39947 20.0672 6.75 20.748 7.30212 21.2411C7.85424 21.7341 8.56881 22.0045 9.30791 22H15.7059C16.445 22.0045 17.1596 21.7341 17.7117 21.2411C18.2638 20.748 18.6144 20.0672 18.6956 19.33L20.4696 3.11003C20.4852 2.96974 20.4712 2.8277 20.4282 2.69326C20.3853 2.55882 20.3145 2.43503 20.2204 2.33003ZM16.6826 19.11C16.6555 19.3557 16.5386 19.5827 16.3546 19.747C16.1705 19.9114 15.9324 20.0015 15.686 20H9.29794C9.05158 20.0015 8.81339 19.9114 8.62935 19.747C8.44531 19.5827 8.32846 19.3557 8.30137 19.11L7.29483 10H17.6991L16.6826 19.11ZM17.9183 8.00003H7.07558L6.63709 4.00003H18.3568L17.9183 8.00003Z"
        fill="#094E91"
      />
    </svg>
  )
}

export default Archive
