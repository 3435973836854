export const TemplateIcon = () => {
  return (
    <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.5 0.833496H12.3333C9.23913 0.833496 6.27167 2.06266 4.08374 4.25058C1.89582 6.43851 0.666656 9.40597 0.666656 12.5002V47.5002C0.666656 50.5944 1.89582 53.5618 4.08374 55.7497C6.27167 57.9377 9.23913 59.1668 12.3333 59.1668H41.5C43.0471 59.1668 44.5308 58.5522 45.6248 57.4583C46.7187 56.3643 47.3333 54.8806 47.3333 53.3335V6.66683C47.3333 5.11973 46.7187 3.636 45.6248 2.54204C44.5308 1.44808 43.0471 0.833496 41.5 0.833496ZM6.49999 12.5002C6.49999 10.9531 7.11457 9.46933 8.20853 8.37537C9.3025 7.28141 10.7862 6.66683 12.3333 6.66683H41.5V35.8335H12.3333C10.2772 35.8421 8.26164 36.4065 6.49999 37.4668V12.5002ZM12.3333 53.3335C10.7862 53.3335 9.3025 52.7189 8.20853 51.6249C7.11457 50.531 6.49999 49.0473 6.49999 47.5002C6.49999 45.9531 7.11457 44.4693 8.20853 43.3754C9.3025 42.2814 10.7862 41.6668 12.3333 41.6668H41.5V53.3335H12.3333ZM18.1667 18.3335H29.8333C30.6069 18.3335 31.3487 18.0262 31.8957 17.4792C32.4427 16.9322 32.75 16.1904 32.75 15.4168C32.75 14.6433 32.4427 13.9014 31.8957 13.3544C31.3487 12.8075 30.6069 12.5002 29.8333 12.5002H18.1667C17.3931 12.5002 16.6512 12.8075 16.1043 13.3544C15.5573 13.9014 15.25 14.6433 15.25 15.4168C15.25 16.1904 15.5573 16.9322 16.1043 17.4792C16.6512 18.0262 17.3931 18.3335 18.1667 18.3335V18.3335Z"
        fill="#094E91"
      />
    </svg>
  )
}
