const Star = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4125 15.8781L15.1375 18.8781C15.7469 19.2625 16.4969 18.6906 16.3187 17.9875L14.95 12.6063C14.913 12.4571 14.9189 12.3005 14.967 12.1546C15.0151 12.0086 15.1034 11.8792 15.2219 11.7813L19.4594 8.2469C20.0125 7.78752 19.7312 6.8594 19.0094 6.81252L13.4781 6.45627C13.3272 6.44749 13.1821 6.39494 13.0606 6.30503C12.939 6.21513 12.8463 6.09177 12.7937 5.95002L10.7312 0.756273C10.6767 0.606182 10.5772 0.476526 10.4464 0.384906C10.3156 0.293286 10.1597 0.244141 10 0.244141C9.84028 0.244141 9.68444 0.293286 9.55362 0.384906C9.4228 0.476526 9.32334 0.606182 9.26875 0.756273L7.20625 5.95002C7.15368 6.09177 7.06096 6.21513 6.93943 6.30503C6.81789 6.39494 6.67279 6.44749 6.52187 6.45627L0.990621 6.81252C0.268746 6.8594 -0.0125041 7.78752 0.540621 8.2469L4.77812 11.7813C4.89656 11.8792 4.98492 12.0086 5.03302 12.1546C5.08112 12.3005 5.08701 12.4571 5.05 12.6063L3.78437 17.5938C3.56875 18.4375 4.46875 19.1219 5.19062 18.6625L9.5875 15.8781C9.71079 15.7997 9.85388 15.7581 10 15.7581C10.1461 15.7581 10.2892 15.7997 10.4125 15.8781Z"
        fill="#FFCF52"
      />
    </svg>
  )
}

export default Star
