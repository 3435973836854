const Reviews = () => {
  return (
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 20.5C18.7614 20.5 21 18.2614 21 15.5C21 12.7386 18.7614 10.5 16 10.5C13.2386 10.5 11 12.7386 11 15.5C11 18.2614 13.2386 20.5 16 20.5Z"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 12.5C25.6647 12.4981 26.8137 12.7683 27.8554 13.2892C28.8972 13.81 29.8028 14.5671 30.5 15.5"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 15.5C2.19725 14.5671 3.10285 13.81 4.14457 13.2892C5.1863 12.7683 6.33532 12.4981 7.5 12.5"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.80005 25C9.45858 23.6513 10.4827 22.5148 11.7556 21.7197C13.0286 20.9247 14.4992 20.5032 16 20.5032C17.5009 20.5032 18.9715 20.9247 20.2445 21.7197C21.5174 22.5148 22.5415 23.6513 23.2 25"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49991 12.5C6.74071 12.5008 5.99697 12.2855 5.35559 11.8792C4.71422 11.473 4.20169 10.8927 3.87789 10.206C3.5541 9.51929 3.43241 8.75464 3.52705 8.00136C3.62169 7.24809 3.92875 6.5373 4.41235 5.95206C4.89595 5.36681 5.53612 4.93127 6.25805 4.69634C6.97998 4.4614 7.75387 4.43677 8.48928 4.62532C9.22469 4.81388 9.89125 5.20782 10.4111 5.76113C10.9309 6.31444 11.2826 7.00427 11.4249 7.75"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5752 7.75C20.7175 7.00427 21.0692 6.31444 21.589 5.76113C22.1089 5.20782 22.7754 4.81388 23.5108 4.62532C24.2462 4.43677 25.0201 4.4614 25.7421 4.69634C26.464 4.93127 27.1042 5.36681 27.5878 5.95206C28.0714 6.5373 28.3784 7.24809 28.4731 8.00136C28.5677 8.75464 28.446 9.51929 28.1222 10.206C27.7984 10.8927 27.2859 11.473 26.6445 11.8792C26.0031 12.2855 25.2594 12.5008 24.5002 12.5"
        stroke="#032D60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Reviews
