export const TelegramFooter = () => {
  return (
    <svg width="75%" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.18105 14.2064C13.65 9.54939 20.631 6.47919 24.1239 4.99582C34.0969 0.760507 36.1692 0.0247884 37.5199 0.000235523C37.817 -0.00484862 38.4812 0.0703222 38.9115 0.42679C39.2748 0.727785 39.3748 1.13439 39.4226 1.41976C39.4704 1.70514 39.53 2.35523 39.4827 2.8632C38.9422 8.66102 36.6037 22.7308 35.414 29.2245C34.9106 31.9722 33.9194 32.8935 32.9598 32.9836C30.8744 33.1796 29.2908 31.5765 27.2709 30.2246C24.1102 28.1092 22.3246 26.7923 19.2566 24.7281C15.711 22.3425 18.0095 21.0313 20.0301 18.8885C20.5589 18.3277 29.7475 9.79431 29.9253 9.0202C29.9476 8.92338 29.9682 8.5625 29.7582 8.37194C29.5483 8.18138 29.2383 8.24655 29.0147 8.29837C28.6977 8.37183 23.6485 11.7793 13.867 18.5209C12.4338 19.5257 11.1356 20.0153 9.97251 19.9897C8.69028 19.9614 6.22379 19.2494 4.3902 18.6409C2.14124 17.8945 0.353803 17.4998 0.509448 16.2322C0.590518 15.5719 1.48105 14.8967 3.18105 14.2064Z"
        fill="white"
      />
    </svg>
  )
}
