export const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19 10.999H13V4.99902C13 4.73381 12.8946 4.47945 12.7071 4.29192C12.5196 4.10438 12.2652 3.99902 12 3.99902C11.7348 3.99902 11.4804 4.10438 11.2929 4.29192C11.1054 4.47945 11 4.73381 11 4.99902V10.999H5C4.73478 10.999 4.48043 11.1044 4.29289 11.2919C4.10536 11.4795 4 11.7338 4 11.999C4 12.2642 4.10536 12.5186 4.29289 12.7061C4.48043 12.8937 4.73478 12.999 5 12.999H11V18.999C11 19.2642 11.1054 19.5186 11.2929 19.7061C11.4804 19.8937 11.7348 19.999 12 19.999C12.2652 19.999 12.5196 19.8937 12.7071 19.7061C12.8946 19.5186 13 19.2642 13 18.999V12.999H19C19.2652 12.999 19.5196 12.8937 19.7071 12.7061C19.8946 12.5186 20 12.2642 20 11.999C20 11.7338 19.8946 11.4795 19.7071 11.2919C19.5196 11.1044 19.2652 10.999 19 10.999Z"
        fill=""
      />
    </svg>
  )
}
