export const Edit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M21.7432 12C21.4789 12 21.2254 12.1054 21.0385 12.2929C20.8516 12.4804 20.7466 12.7348 20.7466 13V19C20.7466 19.2652 20.6416 19.5196 20.4547 19.7071C20.2678 19.8946 20.0143 20 19.75 20H5.79795C5.53365 20 5.28016 19.8946 5.09327 19.7071C4.90637 19.5196 4.80138 19.2652 4.80138 19V5C4.80138 4.73478 4.90637 4.48043 5.09327 4.29289C5.28016 4.10536 5.53365 4 5.79795 4H11.7774C12.0417 4 12.2952 3.89464 12.4821 3.70711C12.669 3.51957 12.774 3.26522 12.774 3C12.774 2.73478 12.669 2.48043 12.4821 2.29289C12.2952 2.10536 12.0417 2 11.7774 2H5.79795C5.00503 2 4.24458 2.31607 3.6839 2.87868C3.12322 3.44129 2.80823 4.20435 2.80823 5V19C2.80823 19.7956 3.12322 20.5587 3.6839 21.1213C4.24458 21.6839 5.00503 22 5.79795 22H19.75C20.5429 22 21.3034 21.6839 21.8641 21.1213C22.4248 20.5587 22.7397 19.7956 22.7397 19V13C22.7397 12.7348 22.6347 12.4804 22.4478 12.2929C22.261 12.1054 22.0075 12 21.7432 12ZM6.79453 12.76V17C6.79453 17.2652 6.89953 17.5196 7.08642 17.7071C7.27331 17.8946 7.5268 18 7.79111 18H12.0166C12.1477 18.0008 12.2778 17.9755 12.3992 17.9258C12.5206 17.876 12.631 17.8027 12.7242 17.71L19.6205 10.78L22.4507 8C22.5441 7.90704 22.6183 7.79644 22.6689 7.67458C22.7195 7.55272 22.7455 7.42201 22.7455 7.29C22.7455 7.15799 22.7195 7.02728 22.6689 6.90542C22.6183 6.78356 22.5441 6.67296 22.4507 6.58L18.2253 2.29C18.1326 2.19627 18.0224 2.12188 17.9009 2.07111C17.7795 2.02034 17.6492 1.9942 17.5177 1.9942C17.3861 1.9942 17.2559 2.02034 17.1344 2.07111C17.013 2.12188 16.9028 2.19627 16.8101 2.29L13.9998 5.12L7.08354 12.05C6.99117 12.1434 6.9181 12.2543 6.8685 12.3761C6.81891 12.4979 6.79377 12.6284 6.79453 12.76ZM17.5177 4.41L20.338 7.24L18.9229 8.66L16.1025 5.83L17.5177 4.41ZM8.78768 13.17L14.6974 7.24L17.5177 10.07L11.608 16H8.78768V13.17Z"
        fill="#094E91"
      />
    </svg>
  )
}
